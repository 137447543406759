import { Button, Stack } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import React, { useState, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { ExtractParams } from '@/types/params';

import NoRowsOverlay from '@/@mui/data-grid/NoRowsOverlay';
import {
  ApiResponse_GetAll_QuestionAnswer,
  useGetAllQuestionAnswers,
} from '@/api/endpoints/questionAnswer/useGetAllQuestionAnswers';
import { QuestionAnswerModel } from '@/api/models/ai/questionAnswer';
import { PlusIcon } from '@/assets/icons/PlusIcon';
import { PMPRoutes } from '@/config/routes';

import RowActionsMenu from '@/components/common/RowActionsMenu';
import QuestionAnswerCreateModal from '@/components/questionAnswer/QuestionAnswerCreateModal';
import QuestionAnswerDeleteModal from '@/components/questionAnswer/QuestionAnswerDeleteModal';
import QuestionAnswerUpdateModal from '@/components/questionAnswer/QuestionAnswerUpdateModal';

const PropertyLocationViewQuestionAnswerListSubpage: React.FC = () => {
  const { id: propertyLocationId } =
    useParams<ExtractParams<typeof PMPRoutes.propertyLocationViewQuestionAnswerList>>();

  const [isQuestionAnswerCreateModalOpen, setIsQuestionAnswerCreateModalOpen] = useState(false);
  const [isQuestionAnswerDeleteModalOpen, setIsQuestionAnswerDeleteModalOpen] = useState(false);
  const [isQuestionAnswerUpdateModalOpen, setIsQuestionAnswerUpdateModalOpen] = useState(false);

  const [questionAnswerToDelete, setQuestionAnswerToDelete] = useState<QuestionAnswerModel | null>(
    null
  );
  const [questionAnswerToUpdate, setQuestionAnswerToUpdate] = useState<QuestionAnswerModel | null>(
    null
  );

  const getAllQuestionAnswersQuery = useGetAllQuestionAnswers({
    location_id: Number(propertyLocationId),
  });

  const onAddQuestionAnswerClick = useCallback(() => {
    setIsQuestionAnswerCreateModalOpen(true);
  }, []);

  const onEditQuestionAnswerClick = useCallback((questionAnswer: QuestionAnswerModel) => {
    setQuestionAnswerToUpdate(questionAnswer);
    setIsQuestionAnswerUpdateModalOpen(true);
  }, []);

  const onDeleteQuestionAnswerClick = useCallback((questionAnswer: QuestionAnswerModel) => {
    setQuestionAnswerToDelete(questionAnswer);
    setIsQuestionAnswerDeleteModalOpen(true);
  }, []);

  const columns: GridColDef<ApiResponse_GetAll_QuestionAnswer[0]>[] = useMemo(
    () => [
      { field: 'id', headerName: 'ID', width: 90 },
      {
        field: 'question',
        headerName: 'Question / Topic',
        minWidth: 200,
        flex: 1,
        renderCell: (
          params: GridRenderCellParams<
            ApiResponse_GetAll_QuestionAnswer[0],
            ApiResponse_GetAll_QuestionAnswer[0]['question']
          >
        ) => <strong>{params.value}</strong>,
      },
      { field: 'answer', headerName: 'Answer / Guidance', minWidth: 200, flex: 2 },
      {
        field: 'actions',
        headerName: '',
        width: 50,
        renderCell: (params: GridRenderCellParams<ApiResponse_GetAll_QuestionAnswer[0]>) => (
          <RowActionsMenu
            items={[
              {
                label: 'Edit',
                onClick: () => onEditQuestionAnswerClick(params.row),
              },
              {
                label: 'Delete',
                onClick: () => onDeleteQuestionAnswerClick(params.row),
              },
            ]}
          />
        ),
      },
    ],
    [onEditQuestionAnswerClick, onDeleteQuestionAnswerClick]
  );

  const BuildingViewQuestionAnswersListNoRowsOverlay = useCallback(
    () => <NoRowsOverlay entityName="Q&A Item" onAddEntityClick={onAddQuestionAnswerClick} />,
    [onAddQuestionAnswerClick]
  );

  return (
    <Stack direction="column" spacing={2} sx={{ flex: '1 1 100%', position: 'relative' }}>
      <Button
        color="primary"
        variant="contained"
        size="medium"
        startIcon={<PlusIcon />}
        onClick={onAddQuestionAnswerClick}
        sx={{
          position: { sm: 'absolute' },
          top: { sm: -54 },
          right: { sm: 0 },
        }}
      >
        Add Q&A Item
      </Button>

      <DataGrid
        rows={getAllQuestionAnswersQuery.data ?? []}
        columns={columns}
        density="comfortable"
        disableColumnFilter
        disableColumnMenu
        disableEval
        disableRowSelectionOnClick
        loading={getAllQuestionAnswersQuery.isLoading}
        slots={{
          noRowsOverlay: BuildingViewQuestionAnswersListNoRowsOverlay,
        }}
        slotProps={{
          loadingOverlay: {
            variant: 'skeleton',
            noRowsVariant: 'skeleton',
          },
        }}
        getRowHeight={() => 'auto'}
      />

      {isQuestionAnswerCreateModalOpen && (
        <QuestionAnswerCreateModal
          onClose={() => setIsQuestionAnswerCreateModalOpen(false)}
          propertyLocationId={propertyLocationId}
        />
      )}

      {isQuestionAnswerUpdateModalOpen && !!questionAnswerToUpdate && (
        <QuestionAnswerUpdateModal
          questionAnswer={questionAnswerToUpdate}
          onClose={() => {
            setQuestionAnswerToUpdate(null);
            setIsQuestionAnswerUpdateModalOpen(false);
          }}
          onQuestionAnswerUpdated={() => getAllQuestionAnswersQuery.refetch()}
        />
      )}

      {isQuestionAnswerDeleteModalOpen && !!questionAnswerToDelete && (
        <QuestionAnswerDeleteModal
          questionAnswer={questionAnswerToDelete}
          onClose={() => {
            setQuestionAnswerToDelete(null);
            setIsQuestionAnswerDeleteModalOpen(false);
          }}
          onQuestionAnswerDeleted={() => getAllQuestionAnswersQuery.refetch()}
        />
      )}
    </Stack>
  );
};

export default PropertyLocationViewQuestionAnswerListSubpage;
