// src/pages/WorkflowPage.tsx

import {
  Box,
  Stack,
  Typography,
  Button,
  Paper,
  IconButton,
  CircularProgress,
  Alert,
} from '@mui/material';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { enqueueSnackbar } from 'notistack';
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { environment } from '@env';

import { getWorkflowNodeTypes, WorkflowPropertyManager } from '@/types/nodeType';
import {
  WorkflowData,
  Point,
  PropertyManager,
  DraggingState,
  NodeType,
  NodeTypes,
  NodeConfig,
  WorkflowConnection,
  WorkflowType,
  DropdownValues,
  ComplexDropdownOption,
} from '@/types/workflow';

import MuiPageWrapper from '@/@mui/MuiPageWrapper';
import { useGetAllTenantsByLocation } from '@/api/endpoints/tenant/useGetAllTenantsByLocation';
import { MinusIcon } from '@/assets/icons/MinusIcon';
import { PlusIcon } from '@/assets/icons/PlusIcon';
import { useAuth } from '@/context/AuthProvider';
import { usePropertyManager } from '@/context/PropertyManagerProvider';
import { getInitialWorkflowState } from '@/utils/workflow.utils';

import { WorkflowCanvas } from '@/components/workflow/WorkflowCanvas';
import { WorkflowTemplate } from '@/components/workflow/WorkflowTemplatesModal';
import WorkflowTypeSelectionModal from '@/components/workflow/WorkflowTypeSelectionModal';

const NODE_TYPES_CONTAINER_WIDTH = 250;

const getNodeBorderColor = (nodeType: string): string => {
  switch (nodeType) {
  case 'trigger':
    return 'primary.main';
  case 'decision':
    return 'warning.main';
  case 'notify':
    return 'success.main';
  case 'form':
    return 'info.main';
  default:
    return 'grey.400';
  }
};

interface LocationState {
  template?: WorkflowTemplate;
}

export const WorkflowPage: React.FC = () => {
  const location = useLocation<LocationState>();
  const queryParams = new URLSearchParams(location.search);
  const workflowId = queryParams.get('id');
  const [workflowType, setWorkflowType] = useState<WorkflowType | undefined>(
    (queryParams.get('type') as WorkflowType) || undefined
  );
  const templateId = queryParams.get('templateId');
  const [showTypeSelection, setShowTypeSelection] = useState(!workflowType && !workflowId);

  const { session } = useAuth();
  const { selectedPropertyLocation } = usePropertyManager();

  const [workflowState, setWorkflowState] = useState<WorkflowData>({
    nodes: [],
    connections: [],
  });
  const [draggingState, setDraggingState] = useState<DraggingState | null>(null);
  const [scale, setScale] = useState(1);
  const [pan, setPan] = useState<Point>({ x: 0, y: 0 });

  const queryClient = useQueryClient();

  const { data: tenants } = useGetAllTenantsByLocation(selectedPropertyLocation?.id ?? 0);
  const defaultWorkflowType: WorkflowType = 'invoice-approval';
  // Fetch property managers
  const { data: propertyManagers } = useQuery<PropertyManager[]>({
    queryKey: ['propertyManagers', selectedPropertyLocation?.id],
    queryFn: async () => {
      if (!selectedPropertyLocation?.id) return [];
      const response = await axios.get(`${environment.api}/property-manager/all`, {
        params: {
          location_id: selectedPropertyLocation.id,
        },
        headers: {
          Authorization: `Bearer ${session?.access_token}`,
        },
      });
      // Transform the response to include the manager's full name and other details
      return response.data.map((pm: PropertyManager) => ({
        ...pm,
        full_name: pm.manager.limitedUser.full_name,
        email: pm.manager.limitedUser.email,
        phone: pm.manager.limitedUser.phone,
      }));
    },
    enabled: !!selectedPropertyLocation?.id,
  });

  const nodeTypes = getWorkflowNodeTypes(
    workflowType || defaultWorkflowType,
    propertyManagers?.map(
      pm =>
        ({
          id: pm.manager.id,
          user_id: pm.manager.user_id,
          full_name: pm.manager.limitedUser.full_name,
          email: pm.manager.limitedUser.email,
          phone: pm.manager.limitedUser.phone,
          role: pm.role,
          is_emergency_contact: pm.manager.is_emergency_contact,
        } as WorkflowPropertyManager)
    ) || [],
    tenants || []
  );
  // Update node configurations when tenants change
  useEffect(() => {
    if (workflowState.nodes.length > 0 && tenants && tenants.length > 0) {
      const updatedNodes = workflowState.nodes.map(node => {
        if (node.type === 'trigger' && node.config?.options) {
          const triggerNodeType = nodeTypes.find(type => type.id === 'trigger');
          if (triggerNodeType?.config) {
            return {
              ...node,
              config: {
                ...node.config,
                options: triggerNodeType.config.options,
              },
            };
          }
        }
        return node;
      });

      if (JSON.stringify(updatedNodes) !== JSON.stringify(workflowState.nodes)) {
        setWorkflowState(prev => ({
          ...prev,
          nodes: updatedNodes,
        }));
      }
    }
  }, [tenants, workflowType, propertyManagers, workflowState.nodes, nodeTypes]);

  // Fetch existing workflow if editing
  const {
    data: workflow,
    isLoading: isLoadingWorkflow,
    error: workflowError,
  } = useQuery({
    queryKey: ['workflow', selectedPropertyLocation?.id, workflowId],
    queryFn: async () => {
      if (!selectedPropertyLocation?.id || !workflowId) return null;
      const response = await axios.get(
        `${environment.api}/workflow/location/${selectedPropertyLocation.id}/workflow/${workflowId}`,
        {
          headers: {
            Authorization: `Bearer ${session?.access_token}`,
          },
        }
      );
      return response.data;
    },
    enabled: !!selectedPropertyLocation?.id && !!workflowId,
  });

  // Fetch template data if templateId is provided
  const { data: templateData } = useQuery({
    queryKey: ['template', selectedPropertyLocation?.id, templateId],
    queryFn: async () => {
      if (!selectedPropertyLocation?.id || !templateId) return null;
      const response = await axios.get(
        `${environment.api}/property-location/${selectedPropertyLocation.id}`,
        {
          headers: {
            Authorization: `Bearer ${session?.access_token}`,
          },
        }
      );
      const workflowsData = response.data.metadata?.workflows || {};
      return workflowsData[templateId] || null;
    },
    enabled: !!selectedPropertyLocation?.id && !!templateId,
  });

  // Save workflow mutation
  const saveMutation = useMutation({
    mutationFn: async () => {
      if (!selectedPropertyLocation?.id) return;

      const endpoint = workflowId
        ? `${environment.api}/workflow/location/${selectedPropertyLocation.id}/workflow/${workflowId}`
        : `${environment.api}/workflow/location/${selectedPropertyLocation.id}/workflow`;

      const method = workflowId ? 'put' : 'post';

      // Create a new workflow object without selectedTenant option
      const workflowToSave = {
        ...workflowState,
        nodes: workflowState.nodes.map((node: { config?: NodeConfig }) => {
          if (!node.config?.options) {
            return node;
          }

          // Filter out the selectedTenant option
          const filteredOptions = (node.config.options as ComplexDropdownOption[]).filter(
            opt => opt.id !== 'selectedTenant'
          );

          return {
            ...node,
            config: {
              ...node.config,
              options: filteredOptions,
            },
          };
        }),
      };

      await axios({
        method,
        url: endpoint,
        data: {
          workflow: {
            ...workflowToSave,
            type: workflowType,
            name: workflowType === 'lease-creation' ? 'Lease Creation' : 'Invoice Approval',
          },
        },
        headers: {
          Authorization: `Bearer ${session?.access_token}`,
        },
      });
    },
  });

  // Activate workflow mutation
  const activateWorkflowMutation = useMutation({
    mutationFn: async () => {
      if (!selectedPropertyLocation?.id || !templateId) return;

      // Create a new workflow object without selectedTenant option
      const workflowToActivate = {
        ...workflowState,
        nodes: workflowState.nodes.map((node: { config?: NodeConfig }) => {
          if (!node.config?.options) {
            return node;
          }

          // Filter out the selectedTenant option
          const filteredOptions = (node.config.options as ComplexDropdownOption[]).filter(
            opt => opt.id !== 'selectedTenant'
          );

          return {
            ...node,
            config: {
              ...node.config,
              options: filteredOptions,
            },
          };
        }),
      };

      const response = await axios.post(
        `${environment.api}/workflow/${selectedPropertyLocation.id}/workflow/instance`,
        {
          workflow: {
            ...workflowToActivate,
            type: workflowType,
            name: workflowType === 'lease-creation' ? 'Lease Creation' : 'Invoice Approval',
          },
          workflowId: templateId,
        },
        {
          headers: {
            Authorization: `Bearer ${session?.access_token}`,
          },
        }
      );

      return response.data;
    },
    onSuccess: () => {
      // Invalidate the template query to refresh the data
      queryClient.invalidateQueries({
        queryKey: ['template', selectedPropertyLocation?.id, templateId],
      });
      enqueueSnackbar(
        'Congratulations! You have started a new workflow. It is available in the workflow section.',
        { variant: 'success' }
      );
    },
  });

  // Initialize workflow state when data is loaded or template is provided
  useEffect(() => {
    if (workflow) {
      setWorkflowState(workflow);
    } else if (templateData) {
      // If template data is provided, use it to initialize the workflow
      const { id: _id, ...rest } = templateData;
      const defaultType: WorkflowType = 'invoice-approval';
      setWorkflowState({
        nodes: rest.nodes.map(
          (node: {
            id: string;
            type: string;
            label: string;
            config: NodeConfig;
            position: Point;
          }) => ({
            ...node,
            type: node.type as NodeTypes,
            config: {
              ...node.config,
              dropdownType: node.config.dropdownType || 'single',
              options: node.config.options || [],
              selectedOption: {
                ...(node.config.selectedOption as DropdownValues),
                selectedTenant: (node.config.selectedOption as DropdownValues)?.selectedTenant,
              },
            } as NodeConfig,
          })
        ),
        connections: rest.connections.map(
          (conn: { id: string; to: string; from: string; label?: string }) => ({
            ...conn,
            label: conn.label as WorkflowConnection['label'],
          })
        ),
        name: workflowType === 'lease-creation' ? 'Lease Creation' : 'Invoice Approval',
        type: workflowType || defaultType,
      });
    } else if (!workflowId && workflowType) {
      setWorkflowState(getInitialWorkflowState(workflowType));
    }
  }, [workflow, workflowId, workflowType, templateData]);

  const handleNodeDragStart = (e: React.DragEvent, type: string) => {
    const rect = e.currentTarget.getBoundingClientRect();
    const offsetX = e.clientX - rect.left;
    const offsetY = e.clientY - rect.top;
    setDraggingState({ type: 'new', nodeType: type, offsetX, offsetY });
  };

  const handleZoomIn = () => {
    setScale((prev: number) => Math.min(2, prev * 1.2));
  };

  const handleZoomOut = () => {
    setScale((prev: number) => Math.max(0.1, prev * 0.8));
  };

  const handleSave = async () => {
    try {
      await saveMutation.mutateAsync();
    } catch (error) {
      // none
    }
  };

  const handleActivateWorkflow = async () => {
    try {
      await activateWorkflowMutation.mutateAsync();
    } catch (error) {
      // Error handling is done via the mutation error state
    }
  };

  const getNodeCursor = (hasLocation: boolean) => (hasLocation ? 'grab' : 'not-allowed');

  const getNodeOpacity = (hasLocation: boolean) => (hasLocation ? 1 : 0.5);

  const handleWorkflowTypeSelect = (selectedType: WorkflowType) => {
    setWorkflowType(selectedType);
    setShowTypeSelection(false);
    setWorkflowState(getInitialWorkflowState(selectedType));
  };

  const renderWorkflowContent = () => {
    if (!workflowType && !workflowId) {
      return (
        <WorkflowTypeSelectionModal
          open={showTypeSelection}
          onClose={() => setShowTypeSelection(false)}
          onSelect={handleWorkflowTypeSelect}
        />
      );
    }

    if (!workflowType) {
      return null;
    }

    return (
      <Stack spacing={2}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h4">
            {workflowType === 'lease-creation' ? 'Lease Creation' : 'Invoice Approval'} Workflow
          </Typography>
          <Stack direction="row" spacing={2}>
            <Stack
              direction="row"
              spacing={1}
              sx={{
                bgcolor: 'background.paper',
                borderRadius: 1,
                p: 0.5,
                boxShadow: 1,
              }}
            >
              <IconButton
                onClick={handleZoomOut}
                size="small"
                disabled={!selectedPropertyLocation?.id}
              >
                <MinusIcon />
              </IconButton>
              <Typography
                variant="body2"
                sx={{
                  minWidth: 40,
                  textAlign: 'center',
                  lineHeight: '24px',
                }}
              >
                {Math.round(scale * 100)}%
              </Typography>
              <IconButton
                onClick={handleZoomIn}
                size="small"
                disabled={!selectedPropertyLocation?.id}
              >
                <PlusIcon />
              </IconButton>
            </Stack>
            {templateId ? (
              <Button
                variant="contained"
                onClick={handleActivateWorkflow}
                disabled={!selectedPropertyLocation?.id || activateWorkflowMutation.isPending}
                color="success"
              >
                {activateWorkflowMutation.isPending && (
                  <CircularProgress size={24} color="inherit" sx={{ mr: 1 }} />
                )}
                Activate Workflow
              </Button>
            ) : (
              <Button
                variant="contained"
                onClick={handleSave}
                disabled={!selectedPropertyLocation?.id || saveMutation.isPending}
              >
                {saveMutation.isPending && (
                  <CircularProgress size={24} color="inherit" sx={{ mr: 1 }} />
                )}
                Save Workflow
              </Button>
            )}
          </Stack>
        </Box>

        {workflowError && (
          <Alert severity="error">Error loading workflow: {(workflowError as Error).message}</Alert>
        )}

        {saveMutation.error && (
          <Alert severity="error">
            Error saving workflow: {(saveMutation.error as Error).message}
          </Alert>
        )}

        {activateWorkflowMutation.error && (
          <Alert severity="error">
            Error activating workflow: {(activateWorkflowMutation.error as Error).message}
          </Alert>
        )}

        {isLoadingWorkflow && selectedPropertyLocation?.id ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', py: 4 }}>
            <CircularProgress />
          </Box>
        ) : (
          <Box sx={{ display: 'flex', gap: 2, height: 'calc(100vh - 200px)' }}>
            <Paper sx={{ p: 2, width: NODE_TYPES_CONTAINER_WIDTH }}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                Components
              </Typography>
              <Stack spacing={1}>
                {nodeTypes.map((type: NodeType) => (
                  <Paper
                    key={type.id}
                    sx={{
                      p: 2,
                      cursor: getNodeCursor(!!selectedPropertyLocation?.id),
                      textAlign: 'center',
                      opacity: getNodeOpacity(!!selectedPropertyLocation?.id),
                      borderLeft: '4px solid',
                      borderLeftColor: getNodeBorderColor(type.id),
                    }}
                    draggable={!!selectedPropertyLocation?.id}
                    onDragStart={e => handleNodeDragStart(e, type.id)}
                  >
                    <Typography variant="body2">{type.label}</Typography>
                  </Paper>
                ))}
              </Stack>
            </Paper>

            <Box sx={{ flex: 1, position: 'relative' }}>
              <WorkflowCanvas
                workflowState={workflowState}
                onWorkflowChange={setWorkflowState}
                scale={scale}
                pan={pan}
                workflowType={workflowType || defaultWorkflowType}
                draggingState={draggingState}
                onDraggingStateChange={setDraggingState}
                propertyManagers={
                  propertyManagers?.map(
                    pm =>
                      ({
                        id: pm.manager.id,
                        user_id: pm.manager.user_id,
                        full_name: pm.manager.limitedUser.full_name,
                        email: pm.manager.limitedUser.email,
                        phone: pm.manager.limitedUser.phone,
                        role: pm.role,
                        is_emergency_contact: pm.manager.is_emergency_contact,
                      } as WorkflowPropertyManager)
                  ) || []
                }
                tenants={tenants || []}
                onPanChange={setPan}
              />
            </Box>
          </Box>
        )}
      </Stack>
    );
  };

  return <MuiPageWrapper>{renderWorkflowContent()}</MuiPageWrapper>;
};

export default WorkflowPage;
