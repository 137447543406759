import {
  Stack,
  Typography,
  useTheme,
  useMediaQuery,
  Chip,
  ToggleButton,
  ToggleButtonGroup,
  Button,
} from '@mui/material';
import { DataGrid, GridCellParams, GridColDef } from '@mui/x-data-grid';
import React, { useCallback, useMemo, useState } from 'react';

import { MessageSessionType } from '@/types/message';

import DashboardHeader from '@/@mui/dashboard/DashboardHeader';
import NoRowsOverlay from '@/@mui/data-grid/NoRowsOverlay';
import { useGetAllSentEmails } from '@/api/endpoints/email/useGetAllSentEmails';
import { EmailSessionModelWithEmailThread } from '@/api/models/emailSession';
import { Uuid } from '@/api/utils/sql';
import { BoxIcon } from '@/assets/icons/BoxIcon';
import { FileAttachment01Icon } from '@/assets/icons/FileAttachment01Icon';
import { Send01Icon } from '@/assets/icons/Send01Icon';
import { usePropertyManager } from '@/context/PropertyManagerProvider';
import { formatFullDateTime } from '@/utils/date';
import { getLastAiResponse, getLastUserMessage } from '@/utils/email';
import { listToLines } from '@/utils/list';
import { capitalizeFirstLetter } from '@/utils/text';

import RowActionsMenu from '@/components/common/RowActionsMenu';
import DocumentViewModal from '@/components/document/DocumentViewModal';
import EmailMobileCard from '@/components/email/EmailMobileCard';
import MessageThreadHistoryModal from '@/components/message/MessageThreadHistoryModal';
import MobileCardsContainer from '@/components/MobileCardsContainer';

const SentEmailListPage: React.FC = () => {
  const { selectedPropertyLocation } = usePropertyManager();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [documentIdToView, setDocumentIdToView] = useState<string | null>(null);
  const [isDocumentViewModalOpen, setIsDocumentViewModalOpen] = useState(false);
  const [isThreadModalOpen, setIsThreadModalOpen] = useState(false);
  const [selectedSessionId, setSelectedSessionId] = useState<Uuid | null>(null);

  const handleOnAttachmentClick = useCallback((documentId: string) => {
    setDocumentIdToView(documentId);
    setIsDocumentViewModalOpen(true);
  }, []);

  const [filterBy, setFilterBy] = useState<'sent' | 'archived'>('sent');

  const handleFilterChange = useCallback(
    (_: React.MouseEvent<HTMLElement>, _filterBy: 'sent' | 'archived') => {
      setFilterBy(_filterBy);
    },
    []
  );

  const getAllSentEmailsQuery = useGetAllSentEmails({
    locationId: selectedPropertyLocation?.id,
    email: selectedPropertyLocation?.email?.value,
    filterBy,
  });

  const onViewHistory = useCallback((sessionId: Uuid) => {
    setSelectedSessionId(sessionId);
    setIsThreadModalOpen(true);
  }, []);

  const columns: GridColDef<EmailSessionModelWithEmailThread>[] = useMemo(
    () => [
      {
        field: 'thread_id',
        headerName: 'ID',
        width: 100,
      },
      {
        field: 'session',
        headerName: 'Tenant',
        width: 250,
        renderCell: (
          params: GridCellParams<
            EmailSessionModelWithEmailThread,
            EmailSessionModelWithEmailThread['session']
          >
        ) =>
          params.value ? (
            <Stack spacing={0.25}>
              <Typography variant="dataGridTitle" noWrap>
                {capitalizeFirstLetter(
                  params.value.from_name ?? params.value.tenant?.full_name ?? 'Unknown'
                )}
              </Typography>

              <Typography variant="dataGridBody" noWrap>
                {params.value.from_email ?? params.value.tenant?.contacts[0]?.email}
              </Typography>

              {params.value.tenant?.unit && (
                <Typography variant="dataGridSmall">
                  {params.value.tenant.unit.building?.name} - {params.value.tenant.unit.name}
                </Typography>
              )}
            </Stack>
          ) : (
            <Typography variant="dataGridTitle" noWrap>
              Unknown
            </Typography>
          ),
      },
      {
        field: 'subjectAndBody',
        headerName: 'Subject & Email',
        width: 400,
        renderCell: (params: GridCellParams<EmailSessionModelWithEmailThread>) => (
          <Stack spacing={0.25}>
            <Typography variant="dataGridTitle">{params.row.session.subject}</Typography>

            <Typography variant="dataGridBody">
              {/* eslint-disable-next-line react/no-danger */}
              <div dangerouslySetInnerHTML={{ __html: getLastUserMessage(params.row) }} />
            </Typography>

            {!!params.row.session?.documents?.length && (
              <Stack direction="row" spacing={1} mt={1} flexWrap="wrap">
                {params.row.session.documents.map(document => (
                  <Button
                    variant="outlined"
                    size="small"
                    color="secondary"
                    startIcon={<FileAttachment01Icon />}
                    sx={{ textTransform: 'none' }}
                    onClick={() => handleOnAttachmentClick(document.id)}
                  >
                    {document.original_name}
                  </Button>
                ))}
              </Stack>
            )}
          </Stack>
        ),
      },
      {
        field: 'last_ai_message',
        headerName: 'Response',
        width: 400,
        renderCell: (params: GridCellParams<EmailSessionModelWithEmailThread>) => (
          <Typography variant="dataGridBody">{getLastAiResponse(params.row)}</Typography>
        ),
      },
      {
        field: 'status',
        headerName: 'Status',
        width: 120,
        renderCell: (params: GridCellParams<EmailSessionModelWithEmailThread>) => (
          <Chip
            label={params.row.session?.archived ? 'Archived' : 'Sent'}
            color={params.row.session?.archived ? 'warning' : 'success'}
            icon={params.row.session?.archived ? <BoxIcon /> : <Send01Icon />}
            variant="light"
            size="small"
          />
        ),
      },
      {
        field: 'session.thread_created_at',
        headerName: 'Created At',
        width: 130,
        valueFormatter: (value: string) => formatFullDateTime(new Date(value)),
        valueGetter: (value: EmailSessionModelWithEmailThread) => value.session.created_at,
        renderCell: (
          params: GridCellParams<
            EmailSessionModelWithEmailThread,
            EmailSessionModelWithEmailThread['session']['created_at']
          >
        ) => listToLines(params.formattedValue?.split(',') ?? []),
      },
      {
        field: 'actions',
        headerName: '',
        align: 'right',
        headerAlign: 'right',
        width: 50,
        renderCell: (params: GridCellParams<EmailSessionModelWithEmailThread>) => (
          <RowActionsMenu
            items={[
              {
                label: 'View History',
                onClick: () => onViewHistory(params.row.session.session_id),
              },
            ]}
          />
        ),
      },
    ],
    [onViewHistory, handleOnAttachmentClick]
  );

  const SentEmailListNoRowsOverlay = useCallback(
    () => <NoRowsOverlay entityName="Sent Email Message" entityNamePlural="Sent Email Messages" />,
    []
  );

  return (
    <Stack direction="column" spacing={2} sx={{ flex: '1 1 100%' }}>
      <DashboardHeader
        hasInnerHtml
        title={`Emails: <em>${filterBy === 'sent' ? 'Sent' : 'Archived'}</em>${
          getAllSentEmailsQuery.data?.length ? ` (${getAllSentEmailsQuery.data?.length})` : ''
        }`}
      />

      <Stack direction="column" spacing={2}>
        <Typography variant="dataGridBody">
          This list shows all email messages that have been sent or archived.
        </Typography>

        <ToggleButtonGroup value={filterBy} exclusive onChange={handleFilterChange}>
          <ToggleButton value="sent">Sent</ToggleButton>
          <ToggleButton value="archived">Archived</ToggleButton>
        </ToggleButtonGroup>
      </Stack>

      {isMobile ? (
        <MobileCardsContainer>
          {getAllSentEmailsQuery.data?.map(email => (
            <EmailMobileCard
              key={email.session.session_id}
              email={email}
              readOnly
              onAttachmentClick={handleOnAttachmentClick}
              onViewHistory={() => onViewHistory(email.session.session_id)}
            />
          ))}
          {getAllSentEmailsQuery.data?.length === 0 && (
            <NoRowsOverlay entityName="Sent Email Message" />
          )}
        </MobileCardsContainer>
      ) : (
        <DataGrid
          rows={getAllSentEmailsQuery.data}
          columns={columns}
          density="comfortable"
          disableColumnFilter
          disableColumnMenu
          disableEval
          disableRowSelectionOnClick
          getRowHeight={() => 'auto'}
          getRowId={row => row.session.session_id}
          loading={getAllSentEmailsQuery.isLoading}
          slots={{
            noRowsOverlay: SentEmailListNoRowsOverlay,
          }}
          slotProps={{
            loadingOverlay: {
              variant: 'skeleton',
              noRowsVariant: 'skeleton',
            },
          }}
        />
      )}

      {isDocumentViewModalOpen && documentIdToView && (
        <DocumentViewModal
          id={documentIdToView}
          onClose={() => {
            setIsDocumentViewModalOpen(false);
            setDocumentIdToView(null);
          }}
        />
      )}

      {isThreadModalOpen && selectedSessionId && (
        <MessageThreadHistoryModal
          threadId={selectedSessionId}
          type={MessageSessionType.email}
          onClose={() => {
            setIsThreadModalOpen(false);
            setSelectedSessionId(null);
          }}
        />
      )}
    </Stack>
  );
};

export default SentEmailListPage;
