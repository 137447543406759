import {
  Box,
  Button,
  Stack,
  Typography,
  Zoom,
  IconButton,
  Collapse,
  Grow,
  Fade,
  Slide,
} from '@mui/material';
import React, { useMemo } from 'react';

import { CheckCircleIcon } from '@/assets/icons/CheckCircleIcon';
import { ChevronDownIcon } from '@/assets/icons/ChevronDownIcon';
import { ChecklistItem } from '@/utils/checklist';

interface Props {
  item: ChecklistItem;
  index: number;
  isExpanded: boolean;
  isNext: boolean;
  onToggleExpand: (item: ChecklistItem) => void;
}

const ChecklistStep: React.FC<Props> = ({ item, index, isExpanded, isNext, onToggleExpand }) => {
  const titleColor = useMemo(() => {
    if (item.isCompleted) return 'text.disabled';
    if (isNext) return 'primary';
    return 'text.secondary';
  }, [item.isCompleted, isNext]);

  return (
    <Grow in timeout={300}>
      <Stack
        sx={{
          p: 2,
          borderRadius: 2,
          mb: 2,
          transition: 'all 0.3s ease',
          border: isNext ? '2px solid' : '1px solid',
          borderColor: isNext ? 'primary.main' : 'divider',
          position: 'relative',
          overflow: 'hidden',
          ...(isNext
            ? {
              borderTopWidth: 4,
            }
            : {}),
        }}
      >
        <Stack direction="row" spacing={2} alignItems="flex-start">
          <Box flex="0 0 auto" mt={1}>
            {item.isCompleted ? (
              <Zoom in>
                <Box component="span" display="block">
                  <CheckCircleIcon color="primary" fontSize="medium" />
                </Box>
              </Zoom>
            ) : (
              <Typography
                variant="subtitle3"
                sx={{
                  width: 24,
                  height: 24,
                  borderRadius: '50%',
                  border: '2px solid',
                  borderColor: isNext ? 'primary.main' : 'grey.400',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: isNext ? 'primary.main' : 'grey.700',
                }}
              >
                {index + 1}
              </Typography>
            )}
          </Box>

          <Stack flex="1 1 100%" spacing={0.5}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography
                variant="subtitle1"
                color={titleColor}
                mb={0.5}
                sx={{ fontWeight: isNext ? 600 : 500 }}
              >
                {item.title}
              </Typography>

              {isNext && (
                <Typography
                  variant="body4"
                  component="span"
                  sx={{
                    color: 'white',
                    bgcolor: 'primary.main',
                    px: 1,
                    py: 0.5,
                    borderRadius: 4,
                    fontWeight: 500,
                  }}
                >
                  Next Step
                </Typography>
              )}
            </Stack>

            <Typography
              variant="body2"
              color={item.isCompleted ? 'text.disabled' : 'text.secondary'}
              sx={{ maxWidth: 720 }}
            >
              {item.description}
            </Typography>
          </Stack>

          <Stack direction="row" spacing={1} alignItems="center">
            <IconButton
              size="small"
              onClick={() => onToggleExpand(item)}
              sx={{
                transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
                transition: 'transform 0.3s ease',
              }}
            >
              <ChevronDownIcon fontSize="small" />
            </IconButton>

            <Button
              variant={isNext ? 'contained' : 'outlined'}
              color={isNext ? 'primary' : 'secondary'}
              size="small"
              href={item.route}
              disabled={item.isDisabled || item.isCompleted}
              sx={{ flex: '0 0 auto' }}
            >
              {item.isCompleted ? 'Completed' : item.actionLabel}
            </Button>
          </Stack>
        </Stack>

        <Collapse in={isExpanded} timeout="auto" unmountOnExit>
          <Fade in timeout={1000}>
            <Box pl={5}>
              <Box
                sx={{
                  my: 2,
                  p: 2,
                  pl: 3,
                  borderLeft: '4px solid',
                  borderColor: 'divider',
                }}
              >
                <Typography variant="subtitle2" mb={1}>
                  Why this matters:
                </Typography>

                <Box
                  sx={{
                    m: 0,
                    pl: 2.5,
                  }}
                  component="ul"
                >
                  {item.benefits?.map(benefit => (
                    <Slide in direction="right" timeout={300} key={benefit}>
                      <li>
                        <Typography variant="body2" gutterBottom>
                          {benefit}
                        </Typography>
                      </li>
                    </Slide>
                  ))}
                </Box>
              </Box>
            </Box>
          </Fade>
        </Collapse>
      </Stack>
    </Grow>
  );
};

export default ChecklistStep;
