import {
  Stack,
  Typography,
  Button,
  Collapse,
  ToggleButtonGroup,
  ToggleButton,
} from '@mui/material';
import { useState } from 'react';
import {
  useLocation,
  useParams,
  Link as RouterLink,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';

import { ExtractParams } from '@/types/params';

import DashboardHeader from '@/@mui/dashboard/DashboardHeader';
import { useGetOneBuilding } from '@/api/endpoints/building/useGetOneBuilding';
import { Mail01Icon } from '@/assets/icons/Mail01Icon';
import { MessageTextCircle02Icon } from '@/assets/icons/MessageTextCircle02Icon';
import { Users01Icon } from '@/assets/icons/Users01Icon';
import { PMPRoutes } from '@/config/routes';
import { addressTextToTwoLinesChunks } from '@/utils/address';
import { listToLines } from '@/utils/list';
import { getResolvedRoutePath } from '@/utils/router';

import EmailBulkSendModal from '@/components/email/EmailBulkSendModal';
import SMSBulkSendModal from '@/components/sms/SMSBulkSendModal';
import InviteTenantModal from '@/components/tenant/InviteTenantModal';

import BuildingViewDocumentListSubpage from './building-view/BuildingViewDocumentListSubpage';
import BuildingViewUnitListSubpage from './building-view/BuildingViewUnitListSubpage';

const BuildingViewPage: React.FC = () => {
  const location = useLocation();
  const { id } = useParams<ExtractParams<PMPRoutes.buildingView>>();

  const [isBulkEmailModalOpen, setIsBulkEmailModalOpen] = useState(false);
  const [isBulkSMSModalOpen, setIsBulkSMSModalOpen] = useState(false);
  const [isInviteTenantModalOpen, setIsInviteTenantModalOpen] = useState(false);

  const [isDescriptionCollapsed, setIsDescriptionCollapsed] = useState(false);

  const getOneBuildingQuery = useGetOneBuilding(Number(id));

  return (
    <Stack direction="column" spacing={2} sx={{ flex: '1 1 100%' }}>
      <DashboardHeader
        breadcrumbs={[{ label: 'Buildings', to: PMPRoutes.buildingList }]}
        title={getOneBuildingQuery.data?.name}
      >
        <Button
          variant="outlined"
          color="secondary"
          startIcon={<Users01Icon />}
          onClick={() => setIsInviteTenantModalOpen(true)}
        >
          Invite Tenants
        </Button>

        <Button
          variant="outlined"
          color="secondary"
          startIcon={<Mail01Icon />}
          onClick={() => setIsBulkEmailModalOpen(true)}
        >
          Send Email
        </Button>

        <Button
          variant="outlined"
          color="secondary"
          startIcon={<MessageTextCircle02Icon />}
          onClick={() => setIsBulkSMSModalOpen(true)}
        >
          Send SMS
        </Button>
      </DashboardHeader>

      {!!getOneBuildingQuery.data?.address_text && (
        <Typography variant="body3">
          {listToLines(addressTextToTwoLinesChunks(getOneBuildingQuery.data?.address_text))}
        </Typography>
      )}

      <Stack spacing={0.75}>
        <Typography variant="body3">
          This is where you can manage units, tenants, documents, and question-and-answer pairs.
        </Typography>

        <Collapse in={isDescriptionCollapsed}>
          <Stack spacing={0.75}>
            <Typography variant="body3">
              These knowledge items are needed to power Terr.ai's engine to respond to emails.
            </Typography>

            <Typography variant="body3">
              Use the tabs below to navigate between different aspects of unit management:
            </Typography>

            <Typography variant="body3" component="ul" sx={{ pl: 2 }}>
              <Typography variant="body3" component="li">
                Units: Manage tenant information and leases for this unit.
              </Typography>

              <Typography variant="body3" component="li">
                Documents: Access and organize important documents associated with this unit.
                Terr.ai will use these documents to answer tenant queries if applicable.
              </Typography>

              <Typography variant="body3" component="li">
                Q&A: Curate the knowledge base that helps Terr.ai respond accurately to inquiries
                about this unit.
              </Typography>
            </Typography>
          </Stack>
        </Collapse>

        <Button
          variant="text"
          size="small"
          onClick={() => setIsDescriptionCollapsed(!isDescriptionCollapsed)}
          sx={{ alignSelf: 'flex-start', p: 0, minWidth: 0 }}
        >
          {isDescriptionCollapsed ? 'View less' : 'View more'}
        </Button>
      </Stack>

      <ToggleButtonGroup value={location.pathname} role="navigation" exclusive>
        <ToggleButton
          value={getResolvedRoutePath(PMPRoutes.buildingViewUnitList, { id })}
          component={RouterLink}
          to={getResolvedRoutePath(PMPRoutes.buildingViewUnitList, { id })}
        >
          Units
        </ToggleButton>

        <ToggleButton
          value={getResolvedRoutePath(PMPRoutes.buildingViewDocumentList, { id })}
          component={RouterLink}
          to={getResolvedRoutePath(PMPRoutes.buildingViewDocumentList, { id })}
        >
          Documents
        </ToggleButton>
      </ToggleButtonGroup>

      <Switch>
        <Route
          exact
          path={PMPRoutes.buildingViewUnitList}
          component={BuildingViewUnitListSubpage}
        />
        <Route
          exact
          path={PMPRoutes.buildingViewDocumentList}
          component={BuildingViewDocumentListSubpage}
        />

        <Route>
          <Redirect to={getResolvedRoutePath(PMPRoutes.buildingViewUnitList, { id })} />
        </Route>
      </Switch>

      {isBulkEmailModalOpen && (
        <EmailBulkSendModal
          buildingId={Number(id)}
          onClose={() => setIsBulkEmailModalOpen(false)}
        />
      )}

      {isBulkSMSModalOpen && (
        <SMSBulkSendModal buildingId={Number(id)} onClose={() => setIsBulkSMSModalOpen(false)} />
      )}

      {isInviteTenantModalOpen && getOneBuildingQuery.data?.property_location_id && (
        <InviteTenantModal
          buildingId={Number(id)}
          locationId={getOneBuildingQuery.data.property_location_id}
          onClose={() => setIsInviteTenantModalOpen(false)}
        />
      )}
    </Stack>
  );
};

export default BuildingViewPage;
