import { Box, Divider, FormHelperText, Paper, Stack, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { ExtractParams } from '@/types/params';

import LoadingButton from '@/@mantis/components/@extended/LoadingButton';
import DashboardHeader from '@/@mui/dashboard/DashboardHeader';
import NoRowsOverlay from '@/@mui/data-grid/NoRowsOverlay';
import MuiPageWrapper from '@/@mui/MuiPageWrapper';
import {
  ApiRequest_GetAllAvailablePhoneNumbers_PropertyLocation,
  ApiResponse_GetAllAvailablePhoneNumbers_PropertyLocation,
  useGetAllAvailablePhoneNumbers,
} from '@/api/endpoints/propertyLocation/useGetAllAvailablePhoneNumbers';
import {
  ApiResponse_GetAllOwnedAndPublicPhoneNumbers_PropertyManager,
  useGetAllOwnedAndPublicPhoneNumbers,
} from '@/api/endpoints/propertyLocationPhone/useGetAllOwnedAndPublicPhoneNumbers';
import { PMPRoutes } from '@/config/routes';
import { formatPhoneNumber } from '@/utils/phone';

import FormTextField from '@/components/form/FormTextField';
import LoadingState from '@/components/LoadingState';
import PropertyLocationPhoneNumberAssignModal from '@/components/propertyLocation/PropertyLocationPhoneNumberSelectModal';

const PropertyLocationPhoneCreatePage: React.FC = () => {
  const { id } = useParams<ExtractParams<PMPRoutes.propertyLocationPhoneCreate>>();

  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState<string | null>(null);
  const [isPhoneNumberSelectModalOpen, setIsPhoneNumberSelectModalOpen] = useState(false);

  const {
    register,
    control,
    watch,
    formState: { errors },
  } = useForm<ApiRequest_GetAllAvailablePhoneNumbers_PropertyLocation>();

  const contains = watch('contains');

  const getAllAvailablePhoneNumbersQuery = useGetAllAvailablePhoneNumbers(
    Number(id),
    { contains },
    !!contains && contains.length > 1
  );

  const getAllOwnedAndPublicPhoneNumbersQuery = useGetAllOwnedAndPublicPhoneNumbers();

  const onSelectPhoneNumberClick = useCallback((phoneNumber: string) => {
    setSelectedPhoneNumber(phoneNumber);
    setIsPhoneNumberSelectModalOpen(true);
  }, []);

  const ownedAndPublicColumns: GridColDef<
    ApiResponse_GetAllOwnedAndPublicPhoneNumbers_PropertyManager[0]
  >[] = useMemo(
    () => [
      {
        field: 'value',
        headerName: 'Phone Number',
        flex: 1,
        valueFormatter: (value: string) => formatPhoneNumber(value),
      },
      { field: 'type', headerName: 'Type', flex: 1 },
      {
        field: 'actions',
        headerName: '',
        sortable: false,
        align: 'right',
        headerAlign: 'right',
        width: 100,
        renderCell: (
          params: GridRenderCellParams<
            ApiResponse_GetAllOwnedAndPublicPhoneNumbers_PropertyManager[0]
          >
        ) => (
          <LoadingButton
            aria-label="Select Phone Number"
            size="small"
            color="success"
            variant="contained"
            onClick={() => onSelectPhoneNumberClick(params.row.value)}
          >
            Select
          </LoadingButton>
        ),
      },
    ],
    [onSelectPhoneNumberClick]
  );

  const purchasableColumns: GridColDef<
    ApiResponse_GetAllAvailablePhoneNumbers_PropertyLocation[0]
  >[] = useMemo(
    () => [
      {
        field: 'phoneNumber',
        headerName: 'Phone Number',
        flex: 1,
        valueFormatter: (value: string) => formatPhoneNumber(value),
      },
      {
        field: 'actions',
        headerName: '',
        sortable: false,
        align: 'right',
        headerAlign: 'right',
        width: 100,
        renderCell: (
          params: GridRenderCellParams<ApiResponse_GetAllAvailablePhoneNumbers_PropertyLocation[0]>
        ) => (
          <LoadingButton
            aria-label="Select Phone Number"
            size="small"
            color="success"
            variant="contained"
            onClick={() => onSelectPhoneNumberClick(params.row.phoneNumber)}
          >
            Select
          </LoadingButton>
        ),
      },
    ],
    [onSelectPhoneNumberClick]
  );

  const PhoneNumberListNoRowsOverlay = useCallback(
    () => (
      <NoRowsOverlay
        entityName="Phone Number"
        detailsText={
          'Search by digits, area code, prefix, phrases, or characters you want in your phone number.\nEnter at least 2 characters.'
        }
      />
    ),
    []
  );

  if (getAllOwnedAndPublicPhoneNumbersQuery.isLoading) return <LoadingState />;

  return (
    <MuiPageWrapper>
      <Box sx={{ width: '100%', height: '100%', overflow: 'auto' }}>
        <Stack direction="column" sx={{ width: '100%' }} spacing={2}>
          <Stack spacing={1}>
            <DashboardHeader
              breadcrumbs={[{ label: 'Locations', to: PMPRoutes.propertyLocationList }]}
              title="Pick a Dedicated Number for Your Property"
            />

            <Typography variant="body1" sx={{ maxWidth: 800 }}>
              This will be the main contact number for your property, used by tenants, vendors, and
              others who need to reach you.
            </Typography>
          </Stack>

          <Paper elevation={1} sx={{ p: 2, borderRadius: 2 }}>
            <Stack spacing={1.5}>
              <Typography variant="h5" color="primary.main">
                Get a unique phone number for your property
              </Typography>

              <Stack component="form" spacing={1.5} maxWidth={400} sx={{ mb: 1.5 }}>
                <FormTextField
                  label="Search criteria"
                  placeholder="Search by digits or phrases"
                  error={!!errors.contains}
                  helperText={errors.contains?.message?.toString()}
                  tooltipText="Search by digits, area code, prefix, phrases, or characters you want in your phone number."
                  required
                  {...register('contains', {
                    required: 'Search criteria is required',
                    minLength: 2,
                    maxLength: 10,
                  })}
                  size="small"
                  control={control}
                  fullWidth
                />

                {!!getAllAvailablePhoneNumbersQuery.isError && (
                  <FormHelperText error>
                    {getAllAvailablePhoneNumbersQuery.error?.message}
                  </FormHelperText>
                )}
              </Stack>

              <DataGrid
                rows={getAllAvailablePhoneNumbersQuery.data ?? []}
                columns={purchasableColumns}
                density="compact"
                disableColumnFilter
                disableColumnMenu
                disableEval
                disableRowSelectionOnClick
                autoHeight={false}
                loading={getAllAvailablePhoneNumbersQuery.isLoading}
                slots={{
                  noRowsOverlay: PhoneNumberListNoRowsOverlay,
                }}
                slotProps={{
                  loadingOverlay: {
                    variant: 'skeleton',
                    noRowsVariant: 'skeleton',
                  },
                }}
                getRowId={row => row.phoneNumber}
                getRowHeight={() => 'auto'}
                sx={{
                  minHeight: 300,
                  height: 300,
                  border: 'none',
                  '& .MuiDataGrid-cell': { py: 1 },
                  '& .MuiDataGrid-virtualScroller': {
                    overflow: 'visible',
                  },
                }}
              />
            </Stack>
          </Paper>

          <Divider sx={{ my: 0.5 }} />

          {!!getAllOwnedAndPublicPhoneNumbersQuery.data?.length && (
            <Paper elevation={1} sx={{ p: 2, borderRadius: 2 }}>
              <Stack spacing={1.5}>
                <Typography variant="h5" color="primary.main">
                  Your Existing Property Phone Numbers
                </Typography>

                <Typography variant="body1" sx={{ mb: 0.5 }}>
                  These are the phone numbers that are currently assigned to your property. You may
                  share your existing private number with other property locations or choose to get
                  a unique number for each location.
                </Typography>

                <DataGrid
                  rows={getAllOwnedAndPublicPhoneNumbersQuery.data ?? []}
                  columns={ownedAndPublicColumns}
                  density="compact"
                  disableColumnFilter
                  disableColumnMenu
                  disableEval
                  hideFooter
                  autoHeight={false}
                  disableRowSelectionOnClick
                  loading={getAllOwnedAndPublicPhoneNumbersQuery.isLoading}
                  slots={{
                    noRowsOverlay: PhoneNumberListNoRowsOverlay,
                  }}
                  slotProps={{
                    loadingOverlay: {
                      variant: 'skeleton',
                      noRowsVariant: 'skeleton',
                    },
                  }}
                  getRowId={row => row.id}
                  getRowHeight={() => 'auto'}
                  sx={{
                    minHeight: 250,
                    height: 250,
                    border: 'none',
                    '& .MuiDataGrid-cell': { py: 1 },
                    '& .MuiDataGrid-virtualScroller': {
                      overflow: 'visible',
                    },
                  }}
                />
              </Stack>
            </Paper>
          )}
        </Stack>
      </Box>

      {isPhoneNumberSelectModalOpen && selectedPhoneNumber && (
        <PropertyLocationPhoneNumberAssignModal
          propertyLocationId={Number(id)}
          phoneNumber={selectedPhoneNumber}
          onClose={() => setIsPhoneNumberSelectModalOpen(false)}
        />
      )}
    </MuiPageWrapper>
  );
};

export default PropertyLocationPhoneCreatePage;
