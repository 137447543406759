import { Button, Stack, Chip } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import { ExtractParams } from '@/types/params';

import {
  ApiResponse_GetAll_PropertyManager,
  useGetAllPropertyManagers,
} from '@/api/endpoints/propertyManager/useGetAllPropertyManagers';
import { PropertyManagementWithManagerWithLimitedUserView } from '@/api/models/propertyManagement';
import { PlusIcon } from '@/assets/icons/PlusIcon';
import { PMPRoutes } from '@/config/routes';
import { formatPhoneNumber } from '@/utils/phone';

import RowActionsMenu from '@/components/common/RowActionsMenu';
import PropertyManagerCreateModal from '@/components/propertyManager/PropertyManagerCreateModal';
import PropertyManagerDeleteModal from '@/components/propertyManager/PropertyManagerDeleteModal';
import PropertyManagerUpdateModal from '@/components/propertyManager/PropertyManagerUpdateModal';

const PropertyLocationViewPropertyManagerListPage: React.FC = () => {
  const { id: propertyLocationId } =
    useParams<ExtractParams<typeof PMPRoutes.propertyLocationManagerList>>();

  const [isPropertyManagerCreateModalOpen, setIsPropertyManagerCreateModalOpen] = useState(false);
  const [isPropertyManagerUpdateModalOpen, setIsPropertyManagerUpdateModalOpen] = useState(false);
  const [isPropertyManagerDeleteModalOpen, setIsPropertyManagerDeleteModalOpen] = useState(false);

  const [propertyManagerToUpdate, setPropertyManagerToUpdate] =
    useState<PropertyManagementWithManagerWithLimitedUserView | null>(null);
  const [propertyManagerToDelete, setPropertyManagerToDelete] =
    useState<PropertyManagementWithManagerWithLimitedUserView | null>(null);

  const getAllPropertyManagersQuery = useGetAllPropertyManagers({
    location_id: Number(propertyLocationId),
  });

  const propertyManagerColumns: GridColDef<ApiResponse_GetAll_PropertyManager[0]>[] = [
    {
      field: 'full_name',
      headerName: 'Name',
      flex: 1,
      renderCell: (params: GridRenderCellParams<ApiResponse_GetAll_PropertyManager[0]>) => (
        <Stack direction="row" spacing={1} alignItems="center">
          {params.row.manager.limitedUser.full_name}
          {params.row.manager.is_emergency_contact && (
            <Chip label="Emergency Contact" color="error" size="small" variant="outlined" />
          )}
        </Stack>
      ),
    },
    {
      field: 'manager.limitedUser.email',
      headerName: 'Email',
      flex: 1,
      valueGetter: (value: undefined, row: ApiResponse_GetAll_PropertyManager[0]) =>
        row.manager.limitedUser.email,
    },
    {
      field: 'manager.limitedUser.phone',
      headerName: 'Phone',
      flex: 1,
      valueGetter: (value: undefined, row: ApiResponse_GetAll_PropertyManager[0]) =>
        row.manager.limitedUser.phone,
      valueFormatter: (value?: string) => (value ? formatPhoneNumber(value) : '–'),
    },
    {
      field: 'role',
      headerName: 'Role',
      width: 100,
      renderCell: (params: GridRenderCellParams<ApiResponse_GetAll_PropertyManager[0]>) => (
        <Chip
          label={params.value}
          size="small"
          variant="outlined"
          color={params.value === 'ADMIN' ? 'primary' : 'secondary'}
        />
      ),
    },
    {
      field: 'actions',
      sortable: false,
      align: 'right',
      headerAlign: 'right',
      headerName: '',
      width: 50,
      renderCell: (params: GridRenderCellParams<ApiResponse_GetAll_PropertyManager[0]>) => (
        <RowActionsMenu
          items={[
            {
              label: 'Edit',
              onClick: () => {
                setPropertyManagerToUpdate(params.row);
                setIsPropertyManagerUpdateModalOpen(true);
              },
            },
            {
              label: 'Delete',
              onClick: () => {
                setPropertyManagerToDelete(params.row);
                setIsPropertyManagerDeleteModalOpen(true);
              },
            },
          ]}
        />
      ),
    },
  ];

  return (
    <Stack direction="column" spacing={2} sx={{ flex: '1 1 100%', position: 'relative' }}>
      <Button
        color="primary"
        variant="contained"
        size="medium"
        startIcon={<PlusIcon />}
        onClick={() => setIsPropertyManagerCreateModalOpen(true)}
        sx={{
          alignSelf: 'flex-end',
          right: { sm: 0 },
          mb: { xs: 2, sm: 0 },
        }}
      >
        Add Manager
      </Button>

      <DataGrid
        rows={getAllPropertyManagersQuery.data ?? []}
        columns={propertyManagerColumns}
        density="comfortable"
        disableColumnFilter
        disableColumnMenu
        disableEval
        loading={getAllPropertyManagersQuery.isLoading}
        disableRowSelectionOnClick
      />

      {isPropertyManagerCreateModalOpen && propertyLocationId && (
        <PropertyManagerCreateModal
          onClose={() => setIsPropertyManagerCreateModalOpen(false)}
          propertyLocationId={Number(propertyLocationId)}
        />
      )}

      {isPropertyManagerUpdateModalOpen && propertyManagerToUpdate && (
        <PropertyManagerUpdateModal
          propertyManager={propertyManagerToUpdate}
          onClose={() => {
            setPropertyManagerToUpdate(null);
            setIsPropertyManagerUpdateModalOpen(false);
          }}
        />
      )}

      {isPropertyManagerDeleteModalOpen && propertyManagerToDelete && (
        <PropertyManagerDeleteModal
          propertyManager={propertyManagerToDelete}
          onClose={() => {
            setPropertyManagerToDelete(null);
            setIsPropertyManagerDeleteModalOpen(false);
          }}
          onPropertyManagerDeleted={() => {
            getAllPropertyManagersQuery.refetch();
          }}
        />
      )}
    </Stack>
  );
};

export default PropertyLocationViewPropertyManagerListPage;
