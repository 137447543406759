import { Stack } from '@mui/material';
import { useMemo } from 'react';

import { useGetAllVendorConversations } from '@/api/endpoints/conversation/useGetAllVendorConversations';
import { MessageStatus } from '@/api/enums';
import { usePropertyManager } from '@/context/PropertyManagerProvider';
import { getInboundMessageItemFromConversation } from '@/utils/inboundMessage';

import LoadingState from '@/components/LoadingState';

import { Section } from './Section';

const InboundMessagesWithVendorsSubpage: React.FC = () => {
  const { selectedPropertyLocation } = usePropertyManager();

  const getAllVendorConversationsQuery = useGetAllVendorConversations({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    location_id: selectedPropertyLocation!.id,
  });

  const conversations = useMemo(
    () => (getAllVendorConversationsQuery.data ?? []).map(getInboundMessageItemFromConversation),
    [getAllVendorConversationsQuery.data]
  );

  const needsResponse = useMemo(
    () =>
      conversations.filter(conversation => conversation.status === MessageStatus.needs_response),
    [conversations]
  );

  const done = useMemo(
    () => conversations.filter(conversation => conversation.status === MessageStatus.done),
    [conversations]
  );

  return (
    <Stack direction="column" spacing={3} sx={{ flex: '1 1 100%', position: 'relative' }}>
      {getAllVendorConversationsQuery.isLoading ? (
        <LoadingState />
      ) : (
        <>
          <Section
            title="Needs Response"
            items={needsResponse ?? []}
            isLoading={getAllVendorConversationsQuery.isLoading}
            isSuccess={getAllVendorConversationsQuery.isSuccess}
            onItemArchived={() => getAllVendorConversationsQuery.refetch()}
          />

          <Section
            title="Done"
            items={done ?? []}
            isLoading={getAllVendorConversationsQuery.isLoading}
            isSuccess={getAllVendorConversationsQuery.isSuccess}
            onItemArchived={() => getAllVendorConversationsQuery.refetch()}
          />
        </>
      )}
    </Stack>
  );
};

export default InboundMessagesWithVendorsSubpage;
