import { Box, Stack } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { environment } from '@env';

import { TDocument } from '@/types/document';
import { ExtractParams } from '@/types/params';

import DashboardHeader from '@/@mui/dashboard/DashboardHeader';
import { PMPRoutes } from '@/config/routes';
import { useAuth } from '@/context/AuthProvider';

import MuiPDFDocument from '@/components/document/MuiPDFDocument';

const DocumentViewPage: React.FC = () => {
  const { id } = useParams<ExtractParams<PMPRoutes.documentView>>();
  const { session } = useAuth();

  const history = useHistory();

  const documentUrl = `${environment.api}/document/doc/${id}?token=${session?.access_token}`;

  const fetchDocumentQuery = useQuery<TDocument>({
    enabled: !!session?.access_token,
    queryKey: ['GET', 'document', id, session?.access_token],
    queryFn: async () => {
      const response = await axios.get(`${environment.api}/document/${id}`, {
        headers: {
          Authorization: `Bearer ${session?.access_token}`,
        },
      });

      return response.data;
    },
  });

  const isImage = fetchDocumentQuery.data?.mime_type.startsWith('image/');

  const breadcrumbs = useMemo(
    () =>
      history.length > 1 ? [{ label: 'Go Back', onClick: () => history.goBack() }] : undefined,
    [history]
  );

  return (
    <Stack direction="column" spacing={2} sx={{ flex: '1 1 100%' }}>
      <DashboardHeader title={fetchDocumentQuery.data?.original_name} breadcrumbs={breadcrumbs} />

      <Box sx={{ flex: '1 1 100px', overflow: 'auto' }}>
        {isImage ? (
          <img src={documentUrl} alt={fetchDocumentQuery.data?.original_name || id} />
        ) : (
          <MuiPDFDocument
            url={documentUrl}
            fileName={fetchDocumentQuery.data?.original_name || id}
          />
        )}
      </Box>
    </Stack>
  );
};

export default DocumentViewPage;
