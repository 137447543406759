import { Card, List, ListItemButton, ListItemText, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import DashboardHeader from '@/@mui/dashboard/DashboardHeader';
import { useGetAllConversations } from '@/api/endpoints/conversation/useGetAllConversations';
import { PMPRoutes } from '@/config/routes';
import { usePropertyManager } from '@/context/PropertyManagerProvider';

import { ConversationGroupCreateModal } from '@/components/conversation/ConversationCreateModal';

const ConversationListPage = () => {
  const history = useHistory();

  const [isCreateConversationDialogOpen, setIsCreateConversationDialogOpen] = useState(false);

  const { selectedPropertyLocation } = usePropertyManager();

  const getAllConversationsQuery = useGetAllConversations({
    location_id: selectedPropertyLocation?.id,
  });

  const handleConversationClick = (conversationId: string) => {
    history.push(`${PMPRoutes.conversationList}/${conversationId}`);
  };

  const renderListContent = () => {
    if (getAllConversationsQuery.isLoading) {
      return (
        <ListItemButton disabled>
          <ListItemText primary="Loading..." />
        </ListItemButton>
      );
    }

    if (!getAllConversationsQuery.data?.length) {
      return (
        <ListItemButton disabled>
          <ListItemText primary="No conversations found" />
        </ListItemButton>
      );
    }

    return getAllConversationsQuery.data.map(conversation => (
      <ListItemButton
        key={conversation.id}
        onClick={() => handleConversationClick(conversation.id)}
        divider
      >
        <ListItemText
          primary={conversation.title}
          secondary={
            <Stack direction="row" spacing={2} alignItems="center">
              <Typography variant="caption" color="text.secondary">
                {conversation.participant_count} participants
              </Typography>
              {conversation.last_message && (
                <>
                  <Typography variant="caption" color="text.secondary">
                    •
                  </Typography>
                  <Typography variant="caption" color="text.secondary">
                    Last message: {conversation.last_message.content}
                  </Typography>
                </>
              )}
            </Stack>
          }
        />
      </ListItemButton>
    ));
  };

  return (
    <Stack direction="column" spacing={2} sx={{ flex: '1 1 100%' }}>
      <DashboardHeader title="Conversations" />

      <Card>
        <List sx={{ width: '100%', bgcolor: 'background.paper' }}>{renderListContent()}</List>
      </Card>

      {isCreateConversationDialogOpen && (
        <ConversationGroupCreateModal
          onClose={() => setIsCreateConversationDialogOpen(false)}
          onConversationGroupCreated={data => {
            getAllConversationsQuery.refetch();
            history.push(`${PMPRoutes.conversationList}/${data.conversation.sid}`);
          }}
        />
      )}
    </Stack>
  );
};

export default ConversationListPage;
