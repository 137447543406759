import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';

import DashboardHeader from '@/@mui/dashboard/DashboardHeader';
import { useCancelSubscription } from '@/api/endpoints/accountSubscription/useCancelSubscription';
import { useGetSubscription } from '@/api/endpoints/accountSubscription/useGetSubscription';
import { useGetSubscriptionPlans } from '@/api/endpoints/accountSubscription/useGetSubscriptionPlans';
import { useAuth } from '@/context/AuthProvider';
import { formatPaddlePrice } from '@/utils/currency';

import PaddleCheckout from './subscription/PaddleCheckout';

// Define types for plans and prices to avoid using 'any'
interface SubscriptionPrice {
  id: string;
  unit_price: {
    amount: string;
    currency_code: string;
  };
  billing_cycle?: {
    interval: string;
  };
  trial_period?: {
    interval: string;
    frequency: number;
  };
}

interface SubscriptionPlan {
  id: string;
  name: string;
  description: string;
  prices: SubscriptionPrice[];
}

const SubscriptionPage = () => {
  const { user } = useAuth();
  const userId = user?.id || '';

  const [selectedPlan, setSelectedPlan] = useState('');
  const [selectedPriceId, setSelectedPriceId] = useState('');

  // Get the user's current subscription
  const { data: subscription, isLoading: loadingSubscription } = useGetSubscription();

  // Get all available subscription plans
  const { data: plans, isLoading: loadingPlans } = useGetSubscriptionPlans();

  // Handle the subscriptionId properly to avoid TypeScript errors
  // When subscription is undefined or doesn't have an id, pass an empty string to avoid null parameter
  const subscriptionId = subscription?.id || '';
  const { mutateAsync: cancelSubscription, isPending: isCancelling } =
    useCancelSubscription(subscriptionId);

  const handlePlanSelect = (planId: string) => {
    setSelectedPlan(planId);
  };

  const handlePriceSelect = (priceId: string) => {
    setSelectedPriceId(priceId);
  };

  const handleCancelSubscription = async () => {
    if (!subscription?.id) return;

    try {
      // Get the cancellation URL from Paddle and redirect to it
      const result = await cancelSubscription();

      if (result?.cancelUrl) {
        // Open Paddle's cancellation page in a new tab
        window.open(result.cancelUrl, '_blank');
        return;
      }

      // Log error only if DEBUG mode is enabled
      if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.error('Missing cancelUrl in response');
      }
    } catch (error) {
      // Error handling is done in the hook
    }
  };

  if (loadingSubscription || loadingPlans) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      <DashboardHeader title="Subscription Management" />
      <Box sx={{ p: 3 }}>
        <Paper sx={{ p: 3, mb: 3 }}>
          <Typography variant="h5" gutterBottom>
            Current Subscription
          </Typography>
          <Divider sx={{ mb: 2 }} />

          {subscription ? (
            <Box>
              <Typography variant="body1">
                <strong>Status:</strong> {subscription.status}
                {subscription.status === 'active' && subscription.scheduled_cancellation && (
                  <Box component="span" sx={{ color: 'warning.main', ml: 1 }}>
                    (Cancels at period end)
                  </Box>
                )}
              </Typography>
              <Typography variant="body1">
                <strong>Plan:</strong> {subscription.plan_name}
              </Typography>
              <Typography variant="body1">
                <strong>Price:</strong> {formatPaddlePrice(subscription.price)}{' '}
                {subscription.currency}
              </Typography>
              <Typography variant="body1">
                <strong>Current Period Ends:</strong>{' '}
                {new Date(subscription.current_billing_period.ends_at).toLocaleDateString()}
              </Typography>

              <Box sx={{ mt: 2 }}>
                <Button
                  variant="outlined"
                  color="error"
                  onClick={handleCancelSubscription}
                  disabled={isCancelling}
                >
                  {isCancelling ? <CircularProgress size={24} /> : 'Cancel Subscription'}
                </Button>
              </Box>
            </Box>
          ) : (
            <Typography variant="body1">
              You don't have an active subscription. Choose a plan below to subscribe.
            </Typography>
          )}
        </Paper>

        <Paper sx={{ p: 3 }}>
          <Typography variant="h5" gutterBottom>
            Available Plans
          </Typography>
          <Divider sx={{ mb: 2 }} />

          {plans && plans.length > 0 ? (
            <Stack spacing={3}>
              <Box>
                {plans.map((plan: SubscriptionPlan) => (
                  <Box key={plan.id} sx={{ mb: 2 }}>
                    <Typography variant="h6">{plan.name}</Typography>
                    <Typography variant="body2">{plan.description}</Typography>
                    <Box sx={{ mt: 1 }}>
                      {plan.prices.map((price: SubscriptionPrice) => (
                        <Button
                          key={price.id}
                          variant={selectedPriceId === price.id ? 'contained' : 'outlined'}
                          color="primary"
                          sx={{ mr: 1, mb: 1 }}
                          onClick={() => {
                            handlePlanSelect(plan.id);
                            handlePriceSelect(price.id);
                          }}
                        >
                          {formatPaddlePrice(price.unit_price.amount)} /{' '}
                          {price.billing_cycle?.interval || 'month'}
                        </Button>
                      ))}
                    </Box>
                  </Box>
                ))}
              </Box>

              {selectedPlan && selectedPriceId && !subscription && (
                <Box sx={{ mt: 2 }}>
                  <PaddleCheckout
                    userId={userId}
                    priceId={selectedPriceId}
                    buttonText="Subscribe Now"
                  />
                </Box>
              )}
            </Stack>
          ) : (
            <Typography variant="body1">No subscription plans available.</Typography>
          )}
        </Paper>
      </Box>
    </Box>
  );
};

export default SubscriptionPage;
