import { LoadingButton } from '@mui/lab';
import { Stack, TextField } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { enqueueSnackbar } from 'notistack';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { environment } from '@env';

import {
  MessageSessionType,
  MessageThreadRole,
  TMessageSession,
  TMessageThread,
} from '@/types/message';
import { ExtractParams } from '@/types/params';

import { useGetEmailThread } from '@/api/endpoints/email/useGetEmailThread';
import { useGetAllEmailSessions } from '@/api/endpoints/emailSession/useGetAllEmailSessions';
import { useOnMessageSend } from '@/api/endpoints/message/useOnMessageSend';
import { useGetAllSmsSessions } from '@/api/endpoints/smsSession/useGetAllSmsSessions';
import { useGetSmsThread } from '@/api/endpoints/twilio/useGetSmsThread';
import { PMPRoutes } from '@/config/routes';
import { useAuth } from '@/context/AuthProvider';
import {
  getMessageSessionFromEmailSession,
  getMessageThreadFromEmailThread,
} from '@/utils/message';
import { getResolvedRoutePath } from '@/utils/router';

import MessageChat from '@/components/message/MessageChat';

const formatDate = (date: Date) => date.toISOString().split('T')[0];
const getDefaultStartDate = () => {
  const date = new Date();
  date.setDate(date.getDate() - 7);
  return formatDate(date);
};

const addDays = (date: string, days: number) => {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + days);
  return formatDate(newDate);
};

const UnitViewMessageListSubpage: React.FC = () => {
  const { session } = useAuth();
  const { id, messageId } = useParams<ExtractParams<PMPRoutes.unitViewMessageView>>();
  const history = useHistory();
  const [selectedMessageSession, setSelectedMessageSession] = useState<TMessageSession | null>(
    null
  );
  const [startDate, setStartDate] = useState<string>(getDefaultStartDate());
  const [endDate, setEndDate] = useState<string>(formatDate(new Date()));

  const getAllEmailSessionsQuery = useGetAllEmailSessions({
    unit_id: Number(id),
  });
  const getAllSmsSessionsQuery = useGetAllSmsSessions({
    unit_id: Number(id),
  });

  const emailMessageSessions: TMessageSession[] = useMemo(
    () =>
      getAllEmailSessionsQuery.data?.map(emailSession =>
        getMessageSessionFromEmailSession(emailSession)
      ) ?? [],
    [getAllEmailSessionsQuery.data]
  );

  const smsMessageSessions: TMessageSession[] = useMemo(
    () =>
      getAllSmsSessionsQuery.data?.map(smsSession => ({
        type: MessageSessionType.sms,
        session_id: smsSession.session_id,
        building_id: smsSession.tenant.unit.building.id,
        property_location_id: smsSession.tenant.unit.building.property_location_id,
        tenant_id: smsSession.tenant.id,
        from: {
          name: smsSession.tenant?.full_name,
          source: smsSession.from_phone_number,
          to: smsSession.to_phone_number,
        },
        updated_at: smsSession.updated_at,
        created_at: smsSession.created_at,
        subject: 'SMS',
      })) ?? [],
    [getAllSmsSessionsQuery.data]
  );

  const allMessageSessions = useMemo(
    () =>
      [...emailMessageSessions, ...smsMessageSessions].sort(
        (a, b) => new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()
      ),
    [emailMessageSessions, smsMessageSessions]
  );

  useEffect(() => {
    if (!allMessageSessions.length) return;

    // Try to open the message with session_id matching the URL
    if (messageId) {
      const foundSession = allMessageSessions.find(_session => _session.session_id === messageId);

      if (foundSession) {
        setSelectedMessageSession(foundSession);
        return;
      }

      // Replace to URL without message session_id
      history.replace(getResolvedRoutePath(PMPRoutes.unitViewMessageList, { id }));
      return;
    }

    // Only open the first message if no session is selected
    if (!selectedMessageSession) {
      history.push(
        getResolvedRoutePath(PMPRoutes.unitViewMessageView, {
          id,
          messageId: allMessageSessions[0].session_id,
        })
      );
    }
  }, [history, id, messageId, allMessageSessions, selectedMessageSession]);

  const onMessageSessionSelect = useCallback(
    (messageSession: TMessageSession) => {
      history.push(
        getResolvedRoutePath(PMPRoutes.unitViewMessageView, {
          id,
          messageId: messageSession.session_id,
        })
      );
    },
    [history, id]
  );

  const getEmailThreadQuery = useGetEmailThread(
    selectedMessageSession?.session_id,
    selectedMessageSession?.type === MessageSessionType.email
  );
  const getSmsThreadQuery = useGetSmsThread(
    selectedMessageSession?.session_id,
    selectedMessageSession?.type === MessageSessionType.sms
  );

  const emailThread = useMemo(
    () => getMessageThreadFromEmailThread(getEmailThreadQuery.data),
    [getEmailThreadQuery.data]
  );

  const smsThread = useMemo(() => {
    if (!getSmsThreadQuery.data) return null;

    return {
      session: selectedMessageSession,
      messages: getSmsThreadQuery.data.messages.map(sms => ({
        id: sms.id,
        message: sms.message,
        created_at: new Date(sms.created_at),
        updated_at: new Date(sms.updated_at),
        role: MessageThreadRole[sms.role as keyof typeof MessageThreadRole],
      })),
    } as TMessageThread;
  }, [getSmsThreadQuery.data, selectedMessageSession]);

  const thread = useMemo(() => {
    if (!selectedMessageSession) return null;

    switch (selectedMessageSession.type) {
    case MessageSessionType.email:
      return emailThread;

    case MessageSessionType.sms:
      return smsThread;
    }

    return null;
  }, [selectedMessageSession, emailThread, smsThread]);

  const fetchSessionsQueryStatus = useMemo(() => {
    if (getAllEmailSessionsQuery.status === 'error' || getAllSmsSessionsQuery.status === 'error')
      return 'error';

    if (
      getAllEmailSessionsQuery.status === 'pending' ||
      getAllSmsSessionsQuery.status === 'pending'
    )
      return 'pending';

    return 'success';
  }, [getAllEmailSessionsQuery.status, getAllSmsSessionsQuery.status]);

  const fetchThreadQueryStatus = useMemo(() => {
    if (!selectedMessageSession) return 'idle';

    switch (selectedMessageSession.type) {
    case MessageSessionType.email:
      return getEmailThreadQuery.status;

    case MessageSessionType.sms:
      return getSmsThreadQuery.status;

    default:
      return 'idle';
    }
  }, [getEmailThreadQuery.status, getSmsThreadQuery.status, selectedMessageSession]);

  const onMessageSend = useOnMessageSend();

  const exportLogsMutation = useMutation({
    mutationFn: async () => {
      if (!startDate || !endDate || !selectedMessageSession?.tenant_id) {
        throw new Error('Please select date range');
      }

      const response = await axios.post(
        `${environment.api}/reports/tenant-logs/export`,
        {
          tenantId: selectedMessageSession.tenant_id,
          startDate,
          endDate,
        },
        {
          headers: {
            Authorization: `Bearer ${session?.access_token}`,
          },
        }
      );

      return response.data;
    },
    onSuccess: data => {
      // Open spreadsheet in new tab
      if (data.data.spreadsheetUrl) {
        window.open(data.data.spreadsheetUrl, '_blank');
      }

      // Handle CSV download
      if (data.data.csvContent) {
        const blob = new Blob([data.data.csvContent], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', data.data.fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
      }

      enqueueSnackbar('Logs exported successfully.', { variant: 'success' });
    },
    onError: () => {
      enqueueSnackbar('Failed to export logs', { variant: 'error' });
    },
  });

  const handleExport = useCallback(() => {
    if (!startDate || !endDate) {
      enqueueSnackbar('Please select date range', { variant: 'error' });
      return;
    }
    exportLogsMutation.mutate();
  }, [startDate, endDate, exportLogsMutation]);

  return (
    <Stack flex="1 1 100px">
      <Stack direction="row" spacing={2} sx={{ mb: 2, justifyContent: 'flex-end' }}>
        <TextField
          label="Start Date"
          type="date"
          value={startDate}
          onChange={e => setStartDate(e.target.value)}
          size="small"
          disabled={exportLogsMutation.isPending}
        />
        <TextField
          label="End Date"
          type="date"
          value={endDate}
          onChange={e => setEndDate(e.target.value)}
          inputProps={{
            min: startDate,
            max: addDays(startDate, 90),
          }}
          size="small"
          disabled={exportLogsMutation.isPending}
        />
        <LoadingButton
          variant="contained"
          onClick={handleExport}
          loading={exportLogsMutation.isPending}
          disabled={!startDate || !endDate || !selectedMessageSession}
        >
          Download Logs
        </LoadingButton>
      </Stack>

      <MessageChat
        messageSessions={allMessageSessions as TMessageSession[]}
        messageThread={thread}
        fetchSessionsQueryStatus={fetchSessionsQueryStatus}
        fetchThreadQueryStatus={fetchThreadQueryStatus}
        selectedMessageSession={selectedMessageSession}
        onMessageSend={onMessageSend}
        onMessageSessionSelect={onMessageSessionSelect}
      />
    </Stack>
  );
};

export default UnitViewMessageListSubpage;
