import { Button, Stack, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { Fragment, useCallback, useState } from 'react';
import {
  useLocation,
  useParams,
  Link as RouterLink,
  Switch,
  Route,
  Redirect,
  useHistory,
} from 'react-router-dom';

import { ExtractParams } from '@/types/params';

import DashboardHeader from '@/@mui/dashboard/DashboardHeader';
import { ApiResponse_Delete_Tenant } from '@/api/endpoints/tenant/useDeleteTenant';
import { useGetOneTenant } from '@/api/endpoints/tenant/useGetOneTenant';
import { ApiResponse_Undelete_Tenant } from '@/api/endpoints/tenant/useUndeleteTenant';
import { BoxIcon } from '@/assets/icons/BoxIcon';
import { CornerUpLeftIcon } from '@/assets/icons/CornerUpLeftIcon';
import { Edit05Icon } from '@/assets/icons/Edit05Icon';
import { Trash01Icon } from '@/assets/icons/Trash01Icon';
import { PMPRoutes } from '@/config/routes';
import { formatPhoneNumber } from '@/utils/phone';
import { getResolvedRoutePath } from '@/utils/router';

import TenantDeleteModal from '@/components/tenant/TenantDeleteModal';
import TenantRetrieveModal from '@/components/tenant/TenantRetrieveModal';
import TenantUpdateModal from '@/components/tenant/TenantUpdateModal';

import TenantViewDocumentListSubpage from './tenant-view/TenantViewDocumentListSubpage';
import TenantViewSMSListSubpage from './tenant-view/TenantViewSMSListSubpage';

const TenantViewPage: React.FC = () => {
  const { id } = useParams<ExtractParams<PMPRoutes.tenantView>>();
  const location = useLocation();
  const history = useHistory();

  const [isTenantUpdateModalOpen, setIsTenantUpdateModalOpen] = useState(false);
  const [isTenantDeleteModalOpen, setIsTenantDeleteModalOpen] = useState(false);
  const [isTenantRetrieveModalOpen, setIsTenantRetrieveModalOpen] = useState(false);

  const getOneTenantQuery = useGetOneTenant(Number(id));

  const handleOnTenantDeleted = useCallback(
    (tenant: ApiResponse_Delete_Tenant) => {
      history.push(
        getResolvedRoutePath(PMPRoutes.unitViewPastTenantList, { id: String(tenant.unit_id) })
      );
    },
    [history]
  );

  const handleOnTenantRetrieved = useCallback(
    (tenant: ApiResponse_Undelete_Tenant) => {
      history.push(
        getResolvedRoutePath(PMPRoutes.unitViewTenantList, { id: String(tenant.unit_id) })
      );
    },
    [history]
  );
  return (
    <Stack direction="column" spacing={2} sx={{ flex: '1 1 100%' }}>
      <DashboardHeader
        breadcrumbs={[
          { label: 'Buildings', to: PMPRoutes.buildingList },
          {
            label: getOneTenantQuery.data?.unit?.building?.name || 'Unnamed',
            to: getResolvedRoutePath(PMPRoutes.buildingView, {
              id: String(getOneTenantQuery.data?.unit.building_id),
            }),
          },
          {
            label: getOneTenantQuery.data?.unit?.name || 'Unnamed',
            to: getResolvedRoutePath(PMPRoutes.unitViewTenantList, {
              id: String(getOneTenantQuery.data?.unit_id),
            }),
          },
        ]}
        title={getOneTenantQuery.data?.full_name || ''}
      >
        <Button
          variant="outlined"
          color="secondary"
          startIcon={<Edit05Icon />}
          disabled={!getOneTenantQuery.data}
          onClick={() => setIsTenantUpdateModalOpen(true)}
        >
          Edit
        </Button>

        {!!getOneTenantQuery.data?.is_archived && (
          <Button
            variant="outlined"
            color="secondary"
            startIcon={<CornerUpLeftIcon />}
            disabled={!getOneTenantQuery.data}
            onClick={() => setIsTenantRetrieveModalOpen(true)}
          >
            Retrieve
          </Button>
        )}

        <Button
          variant="outlined"
          color="error"
          startIcon={getOneTenantQuery.data?.is_archived ? <Trash01Icon /> : <BoxIcon />}
          disabled={!getOneTenantQuery.data}
          onClick={() => setIsTenantDeleteModalOpen(true)}
        >
          {getOneTenantQuery.data?.is_archived ? 'Delete' : 'Archive'}
        </Button>
      </DashboardHeader>

      <Stack direction="column" spacing={0.25} sx={{ display: 'flex' }}>
        {getOneTenantQuery.data?.contacts.map(contact => (
          <Fragment key={contact.id}>
            {!!contact.email && <Typography variant="body3">{contact.email}</Typography>}

            {!!contact.phone && (
              <Typography variant="body3">{formatPhoneNumber(contact.phone)}</Typography>
            )}
          </Fragment>
        ))}
      </Stack>

      <ToggleButtonGroup value={location.pathname} role="navigation" exclusive>
        <ToggleButton
          value={getResolvedRoutePath(PMPRoutes.tenantViewDocumentList, { id })}
          component={RouterLink}
          to={getResolvedRoutePath(PMPRoutes.tenantViewDocumentList, { id })}
        >
          Lease
        </ToggleButton>

        <ToggleButton
          value={getResolvedRoutePath(PMPRoutes.tenantViewSMSList, { id })}
          component={RouterLink}
          to={getResolvedRoutePath(PMPRoutes.tenantViewSMSList, { id })}
        >
          SMS History
        </ToggleButton>
      </ToggleButtonGroup>

      <Switch>
        <Route
          exact
          path={PMPRoutes.tenantViewDocumentList}
          component={TenantViewDocumentListSubpage}
        />
        <Route exact path={PMPRoutes.tenantViewSMSList} component={TenantViewSMSListSubpage} />
        <Route>
          <Redirect to={getResolvedRoutePath(PMPRoutes.tenantViewDocumentList, { id })} />
        </Route>
      </Switch>

      {isTenantUpdateModalOpen && !!getOneTenantQuery.data && (
        <TenantUpdateModal
          onClose={() => setIsTenantUpdateModalOpen(false)}
          tenant={getOneTenantQuery.data}
        />
      )}

      {isTenantDeleteModalOpen && !!getOneTenantQuery.data && (
        <TenantDeleteModal
          onClose={() => setIsTenantDeleteModalOpen(false)}
          tenant={getOneTenantQuery.data}
          onTenantDeleted={handleOnTenantDeleted}
        />
      )}

      {isTenantRetrieveModalOpen &&
        !!getOneTenantQuery.data &&
        getOneTenantQuery.data.is_archived && (
        <TenantRetrieveModal
          onClose={() => setIsTenantRetrieveModalOpen(false)}
          tenant={getOneTenantQuery.data}
          onTenantRetrieved={handleOnTenantRetrieved}
        />
      )}
    </Stack>
  );
};

export default TenantViewPage;
