import { Stack, Typography, Button } from '@mui/material';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { useGetSubscription } from '@/api/endpoints/accountSubscription/useGetSubscription';
import { PMPRoutes } from '@/config/routes';

const SubscriptionSection: React.FC = () => {
  // Only fetch if the component is visible
  const { data: subscription } = useGetSubscription();

  return (
    <Stack spacing={2}>
      <Typography variant="h6">Subscription</Typography>
      <Typography variant="body2" color="textSecondary">
        {subscription
          ? `${subscription.plan_name} - ${subscription.status}`
          : 'Manage your subscription plan and billing details.'}
      </Typography>
      <Button
        variant="contained"
        color="primary"
        component={RouterLink}
        to={PMPRoutes.subscription}
      >
        Manage Subscription
      </Button>
    </Stack>
  );
};

export default SubscriptionSection;
