import { Box, Button, Link, Stack, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { useCallback, useState } from 'react';
import { Redirect } from 'react-router-dom';

import DashboardHeader from '@/@mui/dashboard/DashboardHeader';
import NoRowsOverlay from '@/@mui/data-grid/NoRowsOverlay';
import {
  ApiResponse_GetAll_Building,
  useGetAllBuildings,
} from '@/api/endpoints/building/useGetAllBuildings';
import { PlusIcon } from '@/assets/icons/PlusIcon';
import { PMPRoutes } from '@/config/routes';
import { usePropertyManager } from '@/context/PropertyManagerProvider';
import { addressTextToTwoLinesChunks } from '@/utils/address';
import { listToLines } from '@/utils/list';
import { getResolvedRoutePath } from '@/utils/router';

import BuildingCreateModal from '@/components/building/BuildingCreateModal';

const BuildingListPage: React.FC = () => {
  const { isSingleFamilyAccount } = usePropertyManager();
  const [isBuildingCreateModalOpen, setIsBuildingCreateModalOpen] = useState(false);

  const getAllBuildingsQuery = useGetAllBuildings();

  const columns: GridColDef<ApiResponse_GetAll_Building[0]>[] = [
    {
      field: 'name',
      headerName: 'Name',
      width: 150,
      valueFormatter: value => value ?? 'Unnamed',
      renderCell: (
        params: GridRenderCellParams<
          ApiResponse_GetAll_Building[0],
          ApiResponse_GetAll_Building[0]['name']
        >
      ) => (
        <Link href={getResolvedRoutePath(PMPRoutes.buildingView, { id: String(params.id) })}>
          {params.value}
        </Link>
      ),
    },
    {
      field: 'address_text',
      headerName: 'Address',
      minWidth: 290,
      flex: 1,
      renderCell: (
        params: GridRenderCellParams<
          ApiResponse_GetAll_Building[0],
          ApiResponse_GetAll_Building[0]['address_text']
        >
      ) => (
        <Stack direction="column" spacing={0.25} justifyContent="center" height={66}>
          <Box sx={{ lineHeight: 1.5 }}>
            {params.value ? listToLines(addressTextToTwoLinesChunks(params.value)) : 'N/A'}
          </Box>
        </Stack>
      ),
    },
    {
      field: 'location',
      headerName: 'Location',
      minWidth: 290,
      flex: 1,
      renderCell: (
        params: GridRenderCellParams<
          ApiResponse_GetAll_Building[0],
          ApiResponse_GetAll_Building[0]['location']
        >
      ) =>
        params.value ? (
          <Link
            href={getResolvedRoutePath(PMPRoutes.propertyLocationView, {
              id: String(params.value.id),
            })}
          >
            {params.value.name}
          </Link>
        ) : (
          'N/A'
        ),
    },
    {
      field: '_count.units',
      headerName: 'Units',
      headerAlign: 'right',
      align: 'right',
      width: 90,
      valueGetter: (value, row: ApiResponse_GetAll_Building[0]) => row._count?.units ?? 0,
    },
    {
      field: 'units._count.tenants',
      headerName: 'Tenants',
      headerAlign: 'right',
      align: 'right',
      width: 110,
      valueGetter: (value, row: ApiResponse_GetAll_Building[0]) =>
        row.units.reduce((sum, unit) => sum + unit._count.tenants, 0) ?? 0,
    },
    {
      field: 'issuesCount',
      headerAlign: 'right',
      align: 'right',
      headerName: 'Issues',
      width: 100,
    },
    { field: 'actionRequired', headerName: 'Action Required', minWidth: 300, flex: 2 },
  ];

  const onAddBuildingClick = useCallback(() => {
    setIsBuildingCreateModalOpen(true);
  }, []);

  const BuildingListNoRowsOverlay = useCallback(
    () => <NoRowsOverlay entityName="Building" onAddEntityClick={onAddBuildingClick} />,
    [onAddBuildingClick]
  );

  if (isSingleFamilyAccount) {
    return <Redirect to={PMPRoutes.propertyLocationList} />;
  }

  return (
    <Stack direction="column" spacing={2} sx={{ flex: '1 1 100%' }}>
      <DashboardHeader title="All Buildings">
        <Button
          color="primary"
          variant="contained"
          size="medium"
          startIcon={<PlusIcon />}
          onClick={onAddBuildingClick}
        >
          Add Building
        </Button>
      </DashboardHeader>

      <Typography variant="body3" sx={{ mb: 2 }}>
        This is where you can see the buildings you are managing. You can click on an existing
        building or add a new building to manage tenants and knowledge base.
      </Typography>

      <DataGrid
        rows={getAllBuildingsQuery.data ?? []}
        columns={columns}
        density="comfortable"
        disableColumnFilter
        disableColumnMenu
        disableEval
        loading={getAllBuildingsQuery.isLoading}
        slots={{
          noRowsOverlay: BuildingListNoRowsOverlay,
        }}
        slotProps={{
          loadingOverlay: {
            variant: 'skeleton',
            noRowsVariant: 'skeleton',
          },
        }}
        sortingMode="server"
        disableRowSelectionOnClick
        hideFooter
      />

      {isBuildingCreateModalOpen && (
        <BuildingCreateModal onClose={() => setIsBuildingCreateModalOpen(false)} />
      )}
    </Stack>
  );
};

export default BuildingListPage;
