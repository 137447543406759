import { Button, Stack, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import React, { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { ExtractParams } from '@/types/params';

import NoRowsOverlay from '@/@mui/data-grid/NoRowsOverlay';
import { ApiResponse_GetAll_Rule, useGetAllRules } from '@/api/endpoints/rule/useGetAllRules';
import { PlusIcon } from '@/assets/icons/PlusIcon';
import { PMPRoutes } from '@/config/routes';

import RowActionsMenu from '@/components/common/RowActionsMenu';
import RuleCreateModal from '@/components/rule/RuleCreateModal';
import RuleDeleteModal from '@/components/rule/RuleDeleteModal';
import RuleUpdateModal from '@/components/rule/RuleUpdateModal';

const PropertyLocationViewRulesSubpage: React.FC = () => {
  const { id } = useParams<ExtractParams<PMPRoutes.propertyLocationViewRuleList>>();

  const [isCreateRuleModalOpen, setIsCreateModalOpen] = useState(false);
  const [isUpdateRuleModalOpen, setIsUpdateRuleModalOpen] = useState(false);
  const [isDeleteRuleModalOpen, setIsDeleteRuleModalOpen] = useState(false);

  const [ruleToDelete, setRuleToDelete] = useState<ApiResponse_GetAll_Rule[0] | null>(null);
  const [ruleToUpdate, setRuleToUpdate] = useState<ApiResponse_GetAll_Rule[0] | null>(null);

  const getAllRulesQuery = useGetAllRules({ location_id: Number(id) });

  const columns = useMemo<GridColDef<ApiResponse_GetAll_Rule[0]>[]>(
    () => [
      {
        field: 'id',
        headerName: 'ID',
        width: 50,
      },
      {
        field: 'description',
        headerName: 'Description',
        width: 400,
        flex: 1,
      },
      {
        field: 'expected_output',
        headerName: 'Expected Output',
        width: 400,
        flex: 1,
      },
      {
        field: 'actions',
        type: 'actions',
        headerName: '',
        width: 50,
        renderCell: params => (
          <RowActionsMenu
            items={[
              {
                label: 'Edit',
                onClick: () => {
                  setRuleToUpdate(params.row);
                  setIsUpdateRuleModalOpen(true);
                },
              },
              {
                label: 'Delete',
                onClick: () => {
                  setRuleToDelete(params.row);
                  setIsDeleteRuleModalOpen(true);
                },
              },
            ]}
          />
        ),
      },
    ],
    []
  );

  const RulesNoRowsOverlay = useCallback(() => <NoRowsOverlay entityName="Rule" />, []);

  return (
    <Stack direction="column" spacing={2} sx={{ flex: '1 1 100%' }}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="body2" color="text.secondary" sx={{ maxWidth: '60%' }}>
          Add specific rules for how frequently asked questions about this property should be
          answered. These rules will help ensure consistent and accurate responses.
        </Typography>
        <Button
          variant="contained"
          startIcon={<PlusIcon />}
          onClick={() => setIsCreateModalOpen(true)}
        >
          Add Rule
        </Button>
      </Stack>

      <DataGrid
        rows={getAllRulesQuery.data || []}
        columns={columns}
        density="comfortable"
        disableColumnFilter
        disableColumnMenu
        disableEval
        disableRowSelectionOnClick
        getRowHeight={() => 'auto'}
        loading={getAllRulesQuery.isLoading}
        slots={{
          noRowsOverlay: RulesNoRowsOverlay,
        }}
        slotProps={{
          loadingOverlay: {
            variant: 'skeleton',
            noRowsVariant: 'skeleton',
          },
        }}
      />

      {isCreateRuleModalOpen && (
        <RuleCreateModal
          locationId={Number(id)}
          onClose={() => setIsCreateModalOpen(false)}
          onRuleCreated={() => getAllRulesQuery.refetch()}
        />
      )}

      {isUpdateRuleModalOpen && ruleToUpdate && (
        <RuleUpdateModal
          rule={ruleToUpdate}
          onClose={() => {
            setIsUpdateRuleModalOpen(false);
            setRuleToUpdate(null);
          }}
          onRuleUpdated={() => getAllRulesQuery.refetch()}
        />
      )}

      {isDeleteRuleModalOpen && ruleToDelete && (
        <RuleDeleteModal
          rule={ruleToDelete}
          onClose={() => {
            setIsDeleteRuleModalOpen(false);
            setRuleToDelete(null);
          }}
          onRuleDeleted={() => getAllRulesQuery.refetch()}
        />
      )}
    </Stack>
  );
};

export default PropertyLocationViewRulesSubpage;
