import { Link, Button, Stack } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useCallback, useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';

import { ExtractParams } from '@/types/params';

import NoRowsOverlay from '@/@mui/data-grid/NoRowsOverlay';
import { ApiResponse_GetAll_Unit, useGetAllUnits } from '@/api/endpoints/unit/useGetAllUnits';
import { PlusIcon } from '@/assets/icons/PlusIcon';
import { PMPRoutes } from '@/config/routes';
import { useAuth } from '@/context/AuthProvider';
import { getResolvedRoutePath } from '@/utils/router';

import UnitCreateModal from '@/components/unit/UnitCreateModal';

const columns: GridColDef<ApiResponse_GetAll_Unit[0]>[] = [
  {
    field: 'name',
    headerName: 'Unit',
    minWidth: 100,
    renderCell: params => (
      <Link href={getResolvedRoutePath(PMPRoutes.unitView, { id: String(params.id) })}>
        {params.value}
      </Link>
    ),
  },
  {
    field: 'tenants',
    headerName: 'Tenants',
    minWidth: 200,
    flex: 1,
    valueGetter: (value, row: ApiResponse_GetAll_Unit[0]) =>
      row.tenants.map(tenant => tenant.full_name).join(', '),
  },
];

const BuildingViewUnitListSubpage: React.FC = () => {
  const { session } = useAuth();
  const { id } = useParams<ExtractParams<PMPRoutes.buildingViewUnitList>>();
  const location = useLocation();
  const [isUnitCreateModalOpen, setIsUnitCreateModalOpen] = useState(false);

  const getAllUnitsQuery = useGetAllUnits({ buildingId: Number(id) }, !!session?.access_token);

  const onAddUnitClick = useCallback(() => {
    setIsUnitCreateModalOpen(true);
  }, []);

  const BuildingViewUnitListNoRowsOverlay = useCallback(
    () => <NoRowsOverlay entityName="Unit" onAddEntityClick={onAddUnitClick} />,
    [onAddUnitClick]
  );

  // Auto-open unit create modal if URL has openAddUnit=true
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.get('openAddUnit') === 'true') {
      setIsUnitCreateModalOpen(true);
    }
  }, [location.search]);

  return (
    <Stack direction="column" spacing={2} sx={{ flex: '1 1 100%', position: 'relative' }}>
      <Button
        color="primary"
        variant="contained"
        size="medium"
        startIcon={<PlusIcon />}
        onClick={onAddUnitClick}
        sx={{
          position: { sm: 'absolute' },
          top: { sm: -54 },
          right: { sm: 0 },
        }}
      >
        Add Unit
      </Button>

      <DataGrid
        rows={getAllUnitsQuery.data ?? []}
        columns={columns}
        density="comfortable"
        disableColumnFilter
        disableColumnMenu
        disableEval
        disableRowSelectionOnClick
        loading={getAllUnitsQuery.isLoading}
        slots={{
          noRowsOverlay: BuildingViewUnitListNoRowsOverlay,
        }}
        slotProps={{
          loadingOverlay: {
            variant: 'skeleton',
            noRowsVariant: 'skeleton',
          },
        }}
        sortingMode="server"
      />

      {isUnitCreateModalOpen && (
        <UnitCreateModal buildingId={id} onClose={() => setIsUnitCreateModalOpen(false)} />
      )}
    </Stack>
  );
};

export default BuildingViewUnitListSubpage;
