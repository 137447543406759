import { Stack } from '@mui/material';
import { useCallback } from 'react';

import { TTicket } from '@/types/ticket';

import DashboardHeader from '@/@mui/dashboard/DashboardHeader';
import { useGetAllTickets } from '@/api/endpoints/ticket/useGetAllTickets';
import { useResolveTicket } from '@/api/endpoints/ticket/useResolveTicket';
import { usePropertyManager } from '@/context/PropertyManagerProvider';

import KanbanBoard, { BoardColumn } from '@/components/kanban/KanbanBoard';

const TicketListPage: React.FC = () => {
  const { selectedPropertyLocation } = usePropertyManager();

  const getAllTicketsQuery = useGetAllTickets({ location_id: selectedPropertyLocation?.id });

  const resolveTicketMutation = useResolveTicket({
    onSuccess: () => {
      getAllTicketsQuery.refetch();
    },
  });

  const onTicketAssigned = useCallback(
    (ticket: TTicket) => {
      getAllTicketsQuery.refetch();
    },
    [getAllTicketsQuery]
  );

  const onTicketMovedTo = useCallback(
    (ticket: TTicket, column: BoardColumn) => {
      if (column === BoardColumn.resolved) {
        resolveTicketMutation.mutate({ ticketId: ticket.id });
      }
    },
    [resolveTicketMutation]
  );

  return (
    <Stack direction="column" spacing={2} sx={{ flex: '1 1 100%' }}>
      <DashboardHeader hasInnerHtml title="Tickets: <em>All</em>" />

      <KanbanBoard
        tickets={getAllTicketsQuery.data ?? []}
        onTicketAssigned={onTicketAssigned}
        onTicketMovedTo={onTicketMovedTo}
      />
    </Stack>
  );
};

export default TicketListPage;
