import { Box } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { environment } from '@env';

import { TMessageSession, TMessageThreadMessage } from '@/types/message';
import { ExtractParams } from '@/types/params';

import MainCard from '@/@mantis/components/MainCard';
import { PMPRoutes } from '@/config/routes';
import { useAuth } from '@/context/AuthProvider';

import MessageThreadMessageList from '@/components/message/MessageThreadMessageList';

interface SMSResponse {
  messages: TMessageThreadMessage[];
  session: TMessageSession;
}

const TenantViewSMSListSubpage: React.FC = () => {
  const { id } = useParams<ExtractParams<PMPRoutes.tenantViewSMSList>>();
  const { session } = useAuth();

  const fetchSMSQuery = useQuery({
    enabled: !!session?.access_token && !!id,
    queryKey: ['GET', 'tenant-sms', id, session?.access_token],
    queryFn: async () => {
      const response = await axios.get<SMSResponse>(
        `${environment.api}/twilio/tenant/${id}/messages`,
        {
          headers: {
            Authorization: `Bearer ${session?.access_token}`,
          },
        }
      );
      return response.data;
    },
  });

  const messageThread = useMemo(() => {
    if (!fetchSMSQuery.data?.session) {
      return null;
    }

    return {
      session: fetchSMSQuery.data.session,
      messages: fetchSMSQuery.data.messages,
    };
  }, [fetchSMSQuery]);

  return (
    <MainCard
      content={false}
      sx={{
        bgcolor: 'white',
        borderRadius: '12px',
        borderColor: 'divider',
        flex: '1 1 200px',
        display: 'flex',
      }}
    >
      <Box sx={{ flex: '1 1 200px', overflow: 'auto', display: 'flex' }}>
        <MessageThreadMessageList
          messageThread={messageThread}
          isLoading={fetchSMSQuery.isLoading}
          isFailed={fetchSMSQuery.isError}
        />
      </Box>
    </MainCard>
  );
};

export default TenantViewSMSListSubpage;
