import { Box, Button, Link, Stack } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams, GridSortModel } from '@mui/x-data-grid';
import { useCallback, useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';

import { ExtractParams } from '@/types/params';

import NoRowsOverlay from '@/@mui/data-grid/NoRowsOverlay';
import {
  ApiResponse_GetAll_Building,
  useGetAllBuildings,
} from '@/api/endpoints/building/useGetAllBuildings';
import { PlusIcon } from '@/assets/icons/PlusIcon';
import { PMPRoutes } from '@/config/routes';
import { addressTextToTwoLinesChunks } from '@/utils/address';
import { listToLines } from '@/utils/list';
import { getResolvedRoutePath } from '@/utils/router';

import BuildingCreateModal from '@/components/building/BuildingCreateModal';

const PropertyLocationViewBuildingListSubpage: React.FC = () => {
  const [sortModel, setSortModel] = useState<GridSortModel>([]);
  const [isBuildingCreateModalOpen, setIsBuildingCreateModalOpen] = useState(false);
  const { id } = useParams<ExtractParams<PMPRoutes.propertyLocationManagerList>>();
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.get('openAddBuilding') === 'true') {
      setIsBuildingCreateModalOpen(true);
    }
  }, [location]);

  const onSortModelChange = useCallback(
    (_sortModel: GridSortModel) => setSortModel(_sortModel),
    []
  );

  const getAllBuildingsQuery = useGetAllBuildings({ location_id: Number(id) });

  const columns: GridColDef<ApiResponse_GetAll_Building[0]>[] = [
    {
      field: 'name',
      headerName: 'Name',
      width: 150,
      valueFormatter: value => value ?? 'Unnamed',
      renderCell: (
        params: GridRenderCellParams<
          ApiResponse_GetAll_Building[0],
          ApiResponse_GetAll_Building[0]['name']
        >
      ) => (
        <Link href={getResolvedRoutePath(PMPRoutes.buildingView, { id: String(params.id) })}>
          {params.value}
        </Link>
      ),
    },
    {
      field: 'address_text',
      headerName: 'Address',
      minWidth: 290,
      flex: 1,
      renderCell: (
        params: GridRenderCellParams<
          ApiResponse_GetAll_Building[0],
          ApiResponse_GetAll_Building[0]['address_text']
        >
      ) => (
        <Stack direction="column" spacing={0.25} justifyContent="center" height={66}>
          <Box sx={{ lineHeight: 1.5 }}>
            {params.value ? listToLines(addressTextToTwoLinesChunks(params.value)) : 'N/A'}
          </Box>
        </Stack>
      ),
    },
    {
      field: '_count.units',
      headerName: 'Units',
      headerAlign: 'right',
      align: 'right',
      width: 90,
      valueGetter: (value, row: ApiResponse_GetAll_Building[0]) => row._count?.units ?? 0,
    },
    {
      field: 'units._count.tenants',
      headerName: 'Tenants',
      headerAlign: 'right',
      align: 'right',
      width: 110,
      valueGetter: (value, row: ApiResponse_GetAll_Building[0]) =>
        row.units.reduce((sum, unit) => sum + unit._count.tenants, 0) ?? 0,
    },
    {
      field: 'issuesCount',
      headerAlign: 'right',
      align: 'right',
      headerName: 'Issues',
      width: 100,
    },
    { field: 'actionRequired', headerName: 'Action Required', minWidth: 300, flex: 2 },
  ];

  const onAddBuildingClick = useCallback(() => {
    setIsBuildingCreateModalOpen(true);
  }, []);

  const BuildingListNoRowsOverlay = useCallback(
    () => <NoRowsOverlay entityName="Building" onAddEntityClick={onAddBuildingClick} />,
    [onAddBuildingClick]
  );

  return (
    <Stack direction="column" spacing={2} sx={{ flex: '1 1 100%', position: 'relative' }}>
      <Button
        color="primary"
        variant="contained"
        size="medium"
        startIcon={<PlusIcon />}
        onClick={onAddBuildingClick}
        sx={{
          right: { sm: 0 },
          alignSelf: 'flex-end',
        }}
      >
        Add Building
      </Button>

      <DataGrid
        rows={getAllBuildingsQuery.data ?? []}
        columns={columns}
        density="comfortable"
        disableColumnFilter
        disableColumnMenu
        disableEval
        loading={getAllBuildingsQuery.isLoading}
        slots={{
          noRowsOverlay: BuildingListNoRowsOverlay,
        }}
        slotProps={{
          loadingOverlay: {
            variant: 'skeleton',
            noRowsVariant: 'skeleton',
          },
        }}
        sortingMode="server"
        sortModel={sortModel}
        onSortModelChange={onSortModelChange}
        disableRowSelectionOnClick
      />

      {isBuildingCreateModalOpen && (
        <BuildingCreateModal
          propertyLocationId={id ? Number(id) : undefined}
          onClose={() => setIsBuildingCreateModalOpen(false)}
        />
      )}
    </Stack>
  );
};

export default PropertyLocationViewBuildingListSubpage;
