import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Stack,
  Typography,
  Alert,
  IconButton,
  Collapse,
  Divider,
  Chip,
  Theme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  LinearProgress,
} from '@mui/material';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { environment } from '@env';

import {
  WorkflowData,
  WorkflowNode,
  DropdownValues as BaseDropdownValues,
  ComplexDropdownOption,
} from '@/types/workflow';

import DashboardHeader from '@/@mui/dashboard/DashboardHeader';
import MuiPageWrapper from '@/@mui/MuiPageWrapper';
import { EditIcon } from '@/assets/icons/EditIcon';
import { PlusIcon } from '@/assets/icons/PlusIcon';
import { Trash03Icon } from '@/assets/icons/Trash03Icon';
import { ZoomInIcon } from '@/assets/icons/ZoomInIcon';
import { ZoomOutIcon } from '@/assets/icons/ZoomOutIcon';
import { PMPRoutes } from '@/config/routes';
import { useAuth } from '@/context/AuthProvider';
import { usePropertyManager } from '@/context/PropertyManagerProvider';

import LeaseFormModal, { FormConfig } from '@/components/workflow/LeaseFormModal';
import LeasePreviewModal from '@/components/workflow/LeasePreviewModal';
import SendNotificationModal from '@/components/workflow/SendNotificationModal';
import WorkflowTemplatesModal from '@/components/workflow/WorkflowTemplatesModal';

interface TenantData {
  id: number;
  full_name: string;
  email?: string;
  phone?: string;
  unit_id: number;
  unit_name: string;
}

interface Option {
  id: string;
  value?: string;
  label: string;
}

// Extend the base DropdownValues to override the selectedTenant type
interface DropdownValues extends Omit<BaseDropdownValues, 'selectedTenant'> {
  selectedTenant?: string;
}

interface WorkflowInstance extends WorkflowData {
  id: string;
  workflowId: string;
  invoiceId: string;
  activatedAt?: string;
}

const getNodeStatusColor = (node: WorkflowNode) => {
  if (node.config?.executed || node.type === 'trigger') {
    return 'success.main';
  }
  return 'text.secondary';
};

const isFutureNode = (
  workflow: WorkflowInstance,
  node: WorkflowNode,
  pendingNodes: WorkflowNode[]
): boolean => {
  if (
    node.type === 'trigger' ||
    node.config?.executed ||
    pendingNodes.some(n => n.id === node.id)
  ) {
    return false;
  }

  // Check if this node comes after any pending nodes in the workflow
  const pendingNodeIds = new Set(pendingNodes.map(n => n.id));
  const isReachableFromPending = workflow.connections.some(
    conn => pendingNodeIds.has(conn.from) && conn.to === node.id
  );

  return isReachableFromPending;
};

const getNodeStatusLabel = (node: WorkflowNode) => {
  if (node.config?.executed || node.type === 'trigger') {
    return 'Completed';
  }
  return 'Pending';
};

const getNextNodes = (workflow: WorkflowInstance, currentNodeId: string): WorkflowNode[] => {
  const connections = workflow.connections.filter(conn => conn.from === currentNodeId);
  return connections
    .map(conn => workflow.nodes.find(node => node.id === conn.to))
    .filter(Boolean) as WorkflowNode[];
};

const getExecutedNodes = (workflow: WorkflowInstance): WorkflowNode[] =>
  workflow.nodes.filter(node => {
    // Always mark trigger nodes as executed
    if (node.type === 'trigger') {
      return true;
    }
    return node.config?.executed;
  });

const getPendingNodes = (
  workflow: WorkflowInstance,
  executedNodes: WorkflowNode[]
): WorkflowNode[] => {
  const lastExecutedNode = executedNodes[executedNodes.length - 1];
  if (!lastExecutedNode) {
    // If no nodes are executed, return the first node(s) without incoming connections
    const startNodes = workflow.nodes.filter(
      node => !workflow.connections.some(conn => conn.to === node.id)
    );
    return startNodes;
  }

  return getNextNodes(workflow, lastExecutedNode.id);
};

const findOptionLabel = (options: Option[], id: string): string => {
  const option = options.find(opt => opt.id === id);
  return option?.label ?? id;
};

const getVendorText = (
  vendors: string[] | undefined,
  options: Option[],
  inboundVendorId?: string
): string => {
  if (inboundVendorId && options?.length) {
    const inboundVendor = options.find(opt => opt.id === inboundVendorId);
    return inboundVendor?.label || 'Unknown vendor';
  }
  if (!vendors?.length) return 'any vendor';
  if (!options?.length) return vendors.join(', ');
  return vendors.map(id => findOptionLabel(options, id)).join(', ');
};

const getApproverText = (approvers: string[] | undefined, options: Option[]): string => {
  if (!approvers?.length) return 'no one';
  if (!options?.length) return approvers.join(', ');
  return approvers.map(id => findOptionLabel(options, id)).join(', ');
};

const getRecipientText = (recipients: string[] | undefined, options: Option[]): string => {
  if (!recipients?.length) return '';
  if (!options?.length) return recipients.join(', ');
  return recipients.map(id => findOptionLabel(options, id)).join(', ');
};

const getNodeDetails = (node: WorkflowNode): string => {
  if (!node.config?.selectedOption || typeof node.config.selectedOption !== 'object') {
    return '';
  }

  const options = node.config.selectedOption as DropdownValues;
  const vendorOptions =
    (node.config.options?.find(opt => opt.id === 'vendors') as ComplexDropdownOption)?.options ||
    [];
  const approverOptions =
    (node.config.options?.find(opt => opt.id === 'approvers') as ComplexDropdownOption)?.options ||
    [];
  const recipientOptions =
    (node.config.options?.find(opt => opt.id === 'recipients') as ComplexDropdownOption)?.options ||
    [];

  switch (node.type) {
  case 'trigger':
    if (options.triggerType === 'invoice_received') {
      return `Invoice from: ${getVendorText(
        Array.isArray(options.vendors) ? options.vendors : undefined,
        vendorOptions,
        node.config.inboundVendorId
      )}`;
    }
    if (options.triggerType === 'new_lease_request') {
      if (options.selectedTenant) {
        try {
          const tenant = JSON.parse(options.selectedTenant) as TenantData;
          return `New lease request for tenant: ${tenant.full_name} (${tenant.unit_name})`;
        } catch (error) {
          return 'New lease creation request';
        }
      }
      return 'New lease creation request';
    }
    return `Trigger: ${options.triggerType || 'Unknown type'}`;
  case 'decision': {
    const prefix =
        options.decisionType === 'need_approval' ? 'Needs approval from' : 'Approved by';
    return `${prefix}: ${getApproverText(
      Array.isArray(options.approvers) ? options.approvers : undefined,
      approverOptions
    )}`;
  }
  case 'notify': {
    const parts = [
      `Notify ${options.notifyType || 'someone'}`,
      Array.isArray(options.recipients) && options.recipients.length
        ? ` (Recipients: ${getRecipientText(options.recipients, recipientOptions)})`
        : '',
      options.remindInDays
        ? `, Send reminder in ${options.remindInDays} days if no action is taken`
        : '',
    ];
    return parts.join('');
  }
  case 'form': {
    const formOptions =
        (node.config.options?.find(opt => opt.id === 'formType') as ComplexDropdownOption)
          ?.options || [];
    const formType =
        typeof options.formType === 'string'
          ? findOptionLabel(formOptions, options.formType)
          : 'Unknown form';
    const isRequired = options.required
      ? String(options.required).toLowerCase() === 'true'
      : false;
    return `Form: ${formType}${isRequired ? ' (Required)' : ' (Optional)'}`;
  }
  default:
    return '';
  }
};

const getBorderColor = (theme: Theme, isPending: boolean, isExecuted?: boolean) => {
  if (isPending) {
    return theme.palette.primary.main;
  }
  if (isExecuted) {
    return theme.palette.success.main;
  }
  return theme.palette.divider;
};

const WorkflowCard: React.FC<{ workflow: WorkflowInstance }> = ({ workflow }) => {
  const [expanded, setExpanded] = useState(false);
  const [selectedNotifyNode, setSelectedNotifyNode] = useState<WorkflowNode | null>(null);
  const [selectedFormNode, setSelectedFormNode] = useState<WorkflowNode | null>(null);
  const [selectedDecisionNode, setSelectedDecisionNode] = useState<WorkflowNode | null>(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [selectedNodeToEdit, setSelectedNodeToEdit] = useState<WorkflowNode | null>(null);
  const history = useHistory();
  const { session } = useAuth();
  const { selectedPropertyLocation } = usePropertyManager();
  const queryClient = useQueryClient();

  const executedNodes = getExecutedNodes(workflow);
  const pendingNodes = getPendingNodes(workflow, executedNodes);

  const deleteWorkflowInstance = useMutation({
    mutationFn: async () => {
      if (!workflow.workflowId) {
        throw new Error('Workflow ID is required');
      }
      await axios.delete(
        `${environment.api}/workflow/location/${selectedPropertyLocation?.id}/workflow-instance/${workflow.workflowId}/${workflow.invoiceId}`,
        {
          headers: {
            Authorization: `Bearer ${session?.access_token}`,
          },
        }
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['workflowInstances', selectedPropertyLocation?.id],
      });
    },
  });

  const updateCompletedStep = useMutation({
    mutationFn: async (node: WorkflowNode) => {
      if (!workflow.workflowId || !workflow.invoiceId) {
        throw new Error('Workflow ID and Invoice ID are required');
      }
      await axios.put(
        `${environment.api}/workflow/location/${selectedPropertyLocation?.id}/workflow-instance/${workflow.workflowId}/${workflow.invoiceId}/node/${node.id}`,
        {
          config: {
            ...node.config,
            executed: false,
            executedAt: null,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${session?.access_token}`,
          },
        }
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['workflowInstances', selectedPropertyLocation?.id],
      });
    },
  });

  const handleEditWorkflow = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (!workflow.workflowId) {
      return;
    }
    history.push(`${PMPRoutes.workflows}?id=${workflow.workflowId}&type=${workflow.type}`);
  };

  const handleExpandClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setExpanded(!expanded);
  };

  const handleDelete = async (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsDeleteDialogOpen(true);
  };

  const handleConfirmDelete = async () => {
    try {
      await deleteWorkflowInstance.mutateAsync();
      setIsDeleteDialogOpen(false);
    } catch (error) {
      // error handling remains the same
    }
  };

  const handleCancelDelete = () => {
    setIsDeleteDialogOpen(false);
  };

  const handleInvoiceClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    history.push('/property-manager/invoices');
  };

  const handleSendNotification = (node: WorkflowNode) => (e: React.MouseEvent) => {
    e.stopPropagation();
    setSelectedNotifyNode(node);
  };

  const handleCloseNotificationModal = () => {
    setSelectedNotifyNode(null);
  };

  const handleNodeClick = (node: WorkflowNode) => (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();

    if (node.type === 'form') {
      const selectedOption = node.config?.selectedOption as { formType?: string };
      if (selectedOption?.formType) {
        setSelectedFormNode({ ...node, label: getNodeDetails(node) });
      }
      return;
    }

    if (node.type === 'decision') {
      const selectedOption = node.config?.selectedOption as DropdownValues;
      if (selectedOption?.decisionType === 'review_required') {
        setSelectedDecisionNode(node);
      }
    }
  };

  const handleCloseFormModal = () => {
    setSelectedFormNode(null);
  };

  const handleCloseDecisionModal = () => {
    setSelectedDecisionNode(null);
  };

  const handleEditCompletedStep = (node: WorkflowNode) => (e: React.MouseEvent) => {
    e.stopPropagation();
    setSelectedNodeToEdit(node);
  };

  const handleConfirmEdit = async () => {
    if (selectedNodeToEdit) {
      try {
        await updateCompletedStep.mutateAsync(selectedNodeToEdit);
        setSelectedNodeToEdit(null);
      } catch (error) {
        // Error handling is done via the mutation error state
      }
    }
  };

  return (
    <>
      <Card
        sx={{
          cursor: 'pointer',
          '&:hover': {
            boxShadow: theme => theme.shadows[4],
          },
        }}
        onClick={(e: React.MouseEvent) => {
          const target = e.target as HTMLElement;
          const isFormStep = target.closest('[data-form-step="true"]');
          const isWorkflowStep = target.closest('[data-workflow-step="true"]');
          const isDecisionStep = target.closest('[data-decision-step="true"]');

          if (isWorkflowStep && !isFormStep && !isDecisionStep) {
            e.stopPropagation();
            return;
          }

          if (!isFormStep && !isDecisionStep) {
            handleEditWorkflow(e);
          }
        }}
      >
        <CardContent>
          <Stack spacing={1}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Stack spacing={1}>
                <Typography variant="h6">
                  {workflow.name} - {workflow.id}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Workflow: {workflow.name}
                </Typography>
                {workflow.activatedAt && (
                  <Typography variant="body2" color="text.secondary">
                    Activated: {new Date(workflow.activatedAt).toLocaleString()}
                  </Typography>
                )}
                {Number(workflow.invoiceId) ? (
                  <Typography variant="body2" color="text.secondary">
                    Invoice ID:{' '}
                    <Button
                      variant="text"
                      size="small"
                      onClick={handleInvoiceClick}
                      sx={{
                        p: 0,
                        minWidth: 0,
                        color: 'inherit',
                        textTransform: 'none',
                        '&:hover': {
                          textDecoration: 'underline',
                          background: 'none',
                        },
                      }}
                    >
                      {workflow.invoiceId}
                    </Button>
                  </Typography>
                ) : null}
                <Typography variant="body2" color="text.secondary">
                  Steps: {workflow.nodes.length}
                </Typography>
                {executedNodes.length > 0 && (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, width: '100%' }}>
                    <LinearProgress
                      variant="determinate"
                      value={(executedNodes.length / workflow.nodes.length) * 100}
                      color="success"
                      sx={{
                        flexGrow: 1,
                        '& .MuiLinearProgress-bar': {
                          bgcolor: 'success.main',
                        },
                      }}
                    />
                    <Typography variant="body2" color="text.secondary">
                      {Math.round((executedNodes.length / workflow.nodes.length) * 100)}%
                    </Typography>
                  </Box>
                )}
              </Stack>
              <Stack direction="row" spacing={1}>
                <IconButton
                  onClick={handleDelete}
                  sx={{ color: 'error.main' }}
                  disabled={deleteWorkflowInstance.isPending}
                >
                  <Trash03Icon />
                </IconButton>
                <IconButton
                  onClick={handleExpandClick}
                  sx={{ transform: expanded ? 'rotate(180deg)' : 'none' }}
                >
                  {expanded ? <ZoomOutIcon /> : <ZoomInIcon />}
                </IconButton>
              </Stack>
            </Box>

            <Collapse in={expanded}>
              <Stack spacing={2} sx={{ mt: 2 }}>
                <Divider />
                <Typography variant="subtitle2">Execution Flow:</Typography>
                <Stack spacing={1}>
                  {workflow.nodes.map(node => {
                    const isPending = pendingNodes.some(n => n.id === node.id);
                    const isFuture = isFutureNode(workflow, node, pendingNodes);
                    const incomingConnection = workflow.connections.find(
                      conn => conn.to === node.id
                    );
                    const previousNode = incomingConnection
                      ? workflow.nodes.find(n => n.id === incomingConnection.from)
                      : null;
                    const canSendNotification =
                      node.type === 'notify' &&
                      !node.config?.executed &&
                      previousNode?.config?.executed &&
                      !isFuture;
                    const isCompleted = node.config?.executed || node.type === 'trigger';

                    return (
                      <Box
                        key={node.id}
                        data-form-step={node.type === 'form'}
                        data-decision-step={node.type === 'decision'}
                        data-workflow-step="true"
                        onClick={handleNodeClick(node)}
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          p: 1,
                          borderRadius: 1,
                          bgcolor: isPending ? 'primary.lighter' : 'transparent',
                          border: theme =>
                            `1px solid ${getBorderColor(theme, isPending, isCompleted)}`,
                          cursor:
                            (node.type === 'form' && (isPending || node.config?.executed)) ||
                            (node.type === 'decision' &&
                              (node.config?.selectedOption as DropdownValues)?.decisionType ===
                                'review_required')
                              ? 'pointer'
                              : 'default',
                          '&:hover':
                            (node.type === 'form' && (isPending || node.config?.executed)) ||
                            (node.type === 'decision' &&
                              (node.config?.selectedOption as DropdownValues)?.decisionType ===
                                'review_required')
                              ? {
                                bgcolor: 'action.hover',
                              }
                              : undefined,
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            mb: 0.5,
                          }}
                        >
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <Typography variant="body2" sx={{ fontWeight: 'medium' }}>
                              {node.label || node.type}
                            </Typography>
                            {isPending && <Chip size="small" label="Next" color="primary" />}
                            {incomingConnection?.label && (
                              <Chip
                                size="small"
                                label={`If ${incomingConnection.label}`}
                                color="default"
                                variant="outlined"
                              />
                            )}
                          </Box>
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            {canSendNotification && (
                              <Button
                                size="small"
                                variant="contained"
                                onClick={handleSendNotification(node)}
                              >
                                Send Notification
                              </Button>
                            )}
                            {isCompleted && node.type !== 'trigger' && (
                              <IconButton
                                size="small"
                                onClick={handleEditCompletedStep(node)}
                                sx={{ color: 'primary.main' }}
                              >
                                <EditIcon />
                              </IconButton>
                            )}
                            <Typography variant="body2" color={getNodeStatusColor(node)}>
                              {getNodeStatusLabel(node)}
                            </Typography>
                            {node.config?.executedAt && (
                              <Typography variant="caption" color="text.secondary">
                                {new Date(node.config.executedAt).toLocaleString()}
                              </Typography>
                            )}
                            {workflow?.activatedAt && node.type === 'trigger' && (
                              <Typography variant="caption" color="text.secondary">
                                {new Date(workflow.activatedAt).toLocaleString()}
                              </Typography>
                            )}
                          </Box>
                        </Box>
                        <Typography variant="caption" color="text.secondary">
                          {getNodeDetails(node)}
                        </Typography>
                      </Box>
                    );
                  })}
                </Stack>
              </Stack>
            </Collapse>
          </Stack>
        </CardContent>
      </Card>
      <Dialog open={isDeleteDialogOpen} onClose={handleCancelDelete}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete workflow instance "{workflow.name}"? This action cannot
            be undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete}>Cancel</Button>
          <Button
            onClick={handleConfirmDelete}
            color="error"
            variant="contained"
            disabled={deleteWorkflowInstance.isPending}
          >
            {deleteWorkflowInstance.isPending ? 'Deleting...' : 'Delete'}
          </Button>
        </DialogActions>
      </Dialog>
      {selectedNotifyNode && workflow.workflowId && workflow.invoiceId && (
        <SendNotificationModal
          open={!!selectedNotifyNode}
          onClose={handleCloseNotificationModal}
          locationId={selectedPropertyLocation?.id || 0}
          workflowId={workflow.workflowId}
          invoiceId={workflow.invoiceId}
          nodeId={selectedNotifyNode.id}
        />
      )}
      {selectedFormNode && workflow.workflowId && workflow.invoiceId && (
        <LeaseFormModal
          open={!!selectedFormNode}
          onClose={handleCloseFormModal}
          node={selectedFormNode as WorkflowNode & { config?: FormConfig }}
          locationId={selectedPropertyLocation?.id || 0}
          workflowId={workflow.workflowId}
          invoiceId={workflow.invoiceId}
        />
      )}
      {selectedDecisionNode && workflow.workflowId && workflow.invoiceId && (
        <LeasePreviewModal
          open={!!selectedDecisionNode}
          onClose={handleCloseDecisionModal}
          locationId={selectedPropertyLocation?.id || 0}
          workflowId={workflow.workflowId}
          invoiceId={workflow.invoiceId}
          nodeId={selectedDecisionNode.id}
        />
      )}
      <Dialog open={!!selectedNodeToEdit} onClose={() => setSelectedNodeToEdit(null)}>
        <DialogTitle>Edit Completed Step</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to reopen this completed step? This will allow you to modify and
            resubmit the step.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSelectedNodeToEdit(null)}>Cancel</Button>
          <Button
            onClick={handleConfirmEdit}
            color="primary"
            variant="contained"
            disabled={updateCompletedStep.isPending}
          >
            {updateCompletedStep.isPending ? 'Updating...' : 'Reopen Step'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const OperationsHubWorkflowListPage = () => {
  const history = useHistory();
  const { session } = useAuth();
  const { selectedPropertyLocation } = usePropertyManager();
  const [isTemplatesModalOpen, setIsTemplatesModalOpen] = useState(false);

  const {
    data: workflowInstances,
    isLoading,
    error,
  } = useQuery<WorkflowInstance[]>({
    queryKey: ['workflowInstances', selectedPropertyLocation?.id],
    queryFn: async () => {
      if (!selectedPropertyLocation?.id) return null;
      const response = await axios.get(
        `${environment.api}/workflow/location/${selectedPropertyLocation.id}/workflow-instances`,
        {
          headers: {
            Authorization: `Bearer ${session?.access_token}`,
          },
        }
      );
      return response.data;
    },
    enabled: !!selectedPropertyLocation?.id,
  });

  const instances = workflowInstances || [];

  const handleCreateWorkflow = () => {
    history.push(PMPRoutes.workflows);
  };

  const handleOpenTemplates = () => {
    setIsTemplatesModalOpen(true);
  };

  const handleCloseTemplates = () => {
    setIsTemplatesModalOpen(false);
  };

  const handleSelectTemplate = (template: {
    id: string;
    name: string;
    type: string;
    nodes: Array<{
      id: string;
      type: string;
      label: string;
      config: {
        options: Array<{
          id: string;
          type: string;
          label: string;
          options: Array<{
            id: string;
            label: string;
          }>;
        }>;
        selectedOption: Record<string, unknown>;
        [key: string]: unknown;
      };
      position: {
        x: number;
        y: number;
      };
    }>;
    connections: Array<{
      id: string;
      to: string;
      from: string;
      label?: string;
    }>;
  }) => {
    history.push(`${PMPRoutes.workflows}?templateId=${template.id}&type=${template.type}`);
    setIsTemplatesModalOpen(false);
  };

  if (!selectedPropertyLocation?.id) {
    return (
      <MuiPageWrapper>
        <Alert severity="info">
          Please select a location from the navigation menu to view workflow instances
        </Alert>
      </MuiPageWrapper>
    );
  }

  if (isLoading) {
    return (
      <MuiPageWrapper>
        <Box sx={{ display: 'flex', justifyContent: 'center', py: 4 }}>
          <CircularProgress />
        </Box>
      </MuiPageWrapper>
    );
  }

  if (error) {
    return (
      <MuiPageWrapper>
        <Alert severity="error">Error loading workflow instances: {(error as Error).message}</Alert>
      </MuiPageWrapper>
    );
  }

  return (
    <MuiPageWrapper>
      <Stack spacing={3}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <DashboardHeader hasInnerHtml title="Workflows: <em>Operations Hub</em>" />
          <Stack direction="row" spacing={2}>
            <Button variant="outlined" onClick={handleOpenTemplates}>
              Templates
            </Button>
            <Button variant="contained" startIcon={<PlusIcon />} onClick={handleCreateWorkflow}>
              Create Workflow
            </Button>
          </Stack>
        </Box>

        {instances.length === 0 ? (
          <Alert severity="info">
            No activated workflows found. Create a new workflow using a template to get started.
          </Alert>
        ) : (
          <Stack spacing={2}>
            {instances.map(workflow => (
              <WorkflowCard key={`${workflow.id}`} workflow={workflow} />
            ))}
          </Stack>
        )}
      </Stack>

      <WorkflowTemplatesModal
        open={isTemplatesModalOpen}
        onClose={handleCloseTemplates}
        onSelectTemplate={handleSelectTemplate}
      />
    </MuiPageWrapper>
  );
};

export default OperationsHubWorkflowListPage;
