import { Stack, ToggleButtonGroup, ToggleButton } from '@mui/material';
import React from 'react';
import {
  useParams,
  useLocation,
  Switch,
  Route,
  Redirect,
  Link as RouterLink,
} from 'react-router-dom';

import { ExtractParams } from '@/types/params';

import DashboardHeader from '@/@mui/dashboard/DashboardHeader';
import { useGetOneUnit } from '@/api/endpoints/unit/useGetOneUnit';
import { PMPRoutes } from '@/config/routes';
import { getResolvedRoutePath } from '@/utils/router';

import UnitViewDocumentListSubpage from './unit-view/UnitViewDocumentListSubpage';
import UnitViewMessageListSubpage from './unit-view/UnitViewMessageListSubpage';
import UnitViewPastTenantListSubpage from './unit-view/UnitViewPastTenantListSubpage';
import UnitViewTenantListSubpage from './unit-view/UnitViewTenantListSubpage';

const UnitViewPage: React.FC = () => {
  const { id } = useParams<ExtractParams<PMPRoutes.unitView>>();
  const location = useLocation();

  const getOneUnitQuery = useGetOneUnit(Number(id));

  return (
    <Stack direction="column" spacing={2} sx={{ flex: '1 1 100%' }}>
      <DashboardHeader
        breadcrumbs={[
          { label: 'Buildings', to: PMPRoutes.buildingList },
          {
            label: getOneUnitQuery.data?.building?.name || 'Unnamed',
            to: getResolvedRoutePath(PMPRoutes.buildingView, {
              id: String(getOneUnitQuery.data?.building_id),
            }),
          },
        ]}
        title={getOneUnitQuery.data?.name ? `Unit ${getOneUnitQuery.data?.name}` : undefined}
      />

      <ToggleButtonGroup value={location.pathname} role="navigation" exclusive>
        <ToggleButton
          value={getResolvedRoutePath(PMPRoutes.unitViewTenantList, { id })}
          component={RouterLink}
          to={getResolvedRoutePath(PMPRoutes.unitViewTenantList, { id })}
        >
          Tenants
        </ToggleButton>

        <ToggleButton
          value={getResolvedRoutePath(PMPRoutes.unitViewPastTenantList, { id })}
          component={RouterLink}
          to={getResolvedRoutePath(PMPRoutes.unitViewPastTenantList, { id })}
        >
          Past Tenants
        </ToggleButton>

        <ToggleButton
          value={getResolvedRoutePath(PMPRoutes.unitViewMessageList, { id })}
          component={RouterLink}
          selected={location.pathname.startsWith(
            getResolvedRoutePath(PMPRoutes.unitViewMessageList, { id })
          )}
          to={getResolvedRoutePath(PMPRoutes.unitViewMessageList, { id })}
        >
          Messages
        </ToggleButton>

        <ToggleButton
          value={getResolvedRoutePath(PMPRoutes.unitViewDocumentList, { id })}
          component={RouterLink}
          to={getResolvedRoutePath(PMPRoutes.unitViewDocumentList, { id })}
        >
          Documents
        </ToggleButton>
      </ToggleButtonGroup>

      <Switch>
        <Route exact path={PMPRoutes.unitViewMessageList} component={UnitViewMessageListSubpage} />
        <Route exact path={PMPRoutes.unitViewMessageView} component={UnitViewMessageListSubpage} />
        <Route exact path={PMPRoutes.unitViewTenantList} component={UnitViewTenantListSubpage} />
        <Route
          exact
          path={PMPRoutes.unitViewPastTenantList}
          component={UnitViewPastTenantListSubpage}
        />
        <Route
          exact
          path={PMPRoutes.unitViewDocumentList}
          component={UnitViewDocumentListSubpage}
        />
        <Route>
          <Redirect to={getResolvedRoutePath(PMPRoutes.unitViewTenantList, { id })} />
        </Route>
      </Switch>
    </Stack>
  );
};

export default UnitViewPage;
