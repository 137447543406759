import { Stack } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams, GridSortModel } from '@mui/x-data-grid';
import React from 'react';

import { TDocument } from '@/types/document';

import NoRowsOverlay from '@/@mui/data-grid/NoRowsOverlay';
import { UploadCloud01Icon } from '@/assets/icons/UploadCloud01Icon';
import { DocumentStatusProvider } from '@/context/DocumentStatusProvider';

import DocumentCategoryCell from '@/components/document/DocumentCategoryCell';
import DocumentDeleteModal from '@/components/document/DocumentDeleteModal';
import DocumentUploadButton, {
  API as DocumentUploadButtonAPI,
} from '@/components/document/DocumentUploadButton';

interface DocumentListLayoutProps {
  documents: TDocument[];
  columns: GridColDef[];
  isLoading: boolean;
  sortModel: GridSortModel;
  onSortModelChange: (model: GridSortModel) => void;
  documentUploadButtonRef: React.RefObject<DocumentUploadButtonAPI>;
  isFileUploading: boolean;
  onFileSelect: (file: File) => void;
  onUploadDocumentClick: () => void;
  isDocumentDeleteModalOpen: boolean;
  documentToDelete: TDocument | null;
  onDocumentDeleteModalClose: () => void;
  onDocumentDeleted: () => void;
}

export const DocumentListLayout: React.FC<DocumentListLayoutProps> = ({
  documents,
  columns: baseColumns,
  isLoading,
  sortModel,
  onSortModelChange,
  documentUploadButtonRef,
  isFileUploading,
  onFileSelect,
  onUploadDocumentClick,
  isDocumentDeleteModalOpen,
  documentToDelete,
  onDocumentDeleteModalClose,
  onDocumentDeleted,
}) => {
  const DocumentListNoRowsOverlay = React.useCallback(
    () => (
      <NoRowsOverlay
        entityName="Document"
        addEntityButtonIcon={<UploadCloud01Icon />}
        addEntityButtonText="Upload Document"
        isLoading={isFileUploading}
        onAddEntityClick={onUploadDocumentClick}
      />
    ),
    [onUploadDocumentClick, isFileUploading]
  );

  const columns: GridColDef[] = React.useMemo(() => {
    const columnsWithoutActions = [...baseColumns];
    const actionsColumn = columnsWithoutActions.pop();

    const updatedColumns = [
      ...columnsWithoutActions,
      {
        field: 'category',
        headerName: 'Category',
        width: 200,
        renderCell: (params: GridRenderCellParams<TDocument>) => (
          <DocumentCategoryCell document={params.row} />
        ),
      },
    ];

    if (actionsColumn) {
      updatedColumns.push(actionsColumn);
    }

    return updatedColumns;
  }, [baseColumns]);

  return (
    <DocumentStatusProvider>
      <Stack direction="column" spacing={2} sx={{ flex: '1 1 100%', position: 'relative' }}>
        <DocumentUploadButton
          ref={documentUploadButtonRef}
          isFileUploading={isFileUploading}
          onFileSelect={onFileSelect}
          sx={{
            position: { sm: 'absolute' },
            top: { sm: -54 },
            right: { sm: 0 },
          }}
        />

        <DataGrid
          rows={documents}
          columns={columns}
          density="comfortable"
          disableColumnFilter
          disableColumnMenu
          disableEval
          loading={isLoading}
          slots={{
            noRowsOverlay: DocumentListNoRowsOverlay,
          }}
          slotProps={{
            loadingOverlay: {
              variant: 'skeleton',
              noRowsVariant: 'skeleton',
            },
          }}
          sortingMode="server"
          sortModel={sortModel}
          onSortModelChange={onSortModelChange}
          disableRowSelectionOnClick
        />

        {isDocumentDeleteModalOpen && documentToDelete && (
          <DocumentDeleteModal
            document={documentToDelete}
            onClose={onDocumentDeleteModalClose}
            onDocumentDeleted={onDocumentDeleted}
          />
        )}
      </Stack>
    </DocumentStatusProvider>
  );
};
