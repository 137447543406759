import { Avatar, Button, Stack, Typography, useTheme, useMediaQuery } from '@mui/material';
import { DataGrid, GridCellParams, GridColDef } from '@mui/x-data-grid';
import { useState, useCallback, useMemo } from 'react';

import { InboundMessageItem } from '@/types/inboundMessage';
import { MessageSessionType } from '@/types/message';

import { useArchiveVendorConversation } from '@/api/endpoints/conversation/useArchiveVendorConversation';
import { useArchiveEmail } from '@/api/endpoints/email/useArchiveEmail';
import { useArchiveSms } from '@/api/endpoints/twilio/useArchiveSms';
import { MessageStatus } from '@/api/enums';
import { Uuid } from '@/api/utils/sql';
import { AlertCircleIcon } from '@/assets/icons/AlertCircleIcon';
import { BoxIcon } from '@/assets/icons/BoxIcon';
import { FileAttachment01Icon } from '@/assets/icons/FileAttachment01Icon';
import { Mail02Icon } from '@/assets/icons/Mail02Icon';
import { Phone02Icon } from '@/assets/icons/Phone02Icon';
import { getFromNow } from '@/utils/date';
import { getInitialsFromFullName } from '@/utils/user';

import RowActionsMenu from '@/components/common/RowActionsMenu';
import DocumentViewModal from '@/components/document/DocumentViewModal';
import MessageThreadHistoryModal from '@/components/message/MessageThreadHistoryModal';

interface Props {
  title: string;
  items: InboundMessageItem[];
  isLoading: boolean;
  isSuccess: boolean;
  onItemArchived?: () => void;
}

export const Section: React.FC<Props> = ({
  title,
  items,
  isLoading,
  isSuccess,
  onItemArchived,
}) => {
  const [documentIdToView, setDocumentIdToView] = useState<Uuid | null>(null);
  const [isDocumentViewModalOpen, setIsDocumentViewModalOpen] = useState(false);

  const [threadToView, setThreadToView] = useState<InboundMessageItem | null>(null);
  const [isThreadModalOpen, setIsThreadModalOpen] = useState(false);

  const archiveEmailMutation = useArchiveEmail({
    onSuccess() {
      onItemArchived?.();
    },
  });

  const archiveSmsMutation = useArchiveSms({
    onSuccess() {
      onItemArchived?.();
    },
  });

  const archiveVendorConversationMutation = useArchiveVendorConversation({
    onSuccess() {
      onItemArchived?.();
    },
  });

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleOnAttachmentClick = useCallback((documentId: string) => {
    setDocumentIdToView(documentId);
    setIsDocumentViewModalOpen(true);
  }, []);

  const onMarkAsDone = useCallback(
    async (message: InboundMessageItem) => {
      switch (message.type) {
      case MessageSessionType.email:
        await archiveEmailMutation.mutateAsync({
          thread_external_id: message.thread_external_id,
        });
        return;

      case MessageSessionType.sms:
        await archiveSmsMutation.mutateAsync({
          thread_external_id: message.thread_external_id,
        });
        return;

      case MessageSessionType.vendor:
        await archiveVendorConversationMutation.mutateAsync({
          conversation_sid: message.thread_external_id,
        });
      }
    },
    [archiveEmailMutation, archiveSmsMutation, archiveVendorConversationMutation]
  );

  const getIsMarkAsDoneLoading = useCallback(
    (message: InboundMessageItem) => {
      switch (message.type) {
      case MessageSessionType.email:
        return (
          archiveEmailMutation.isPending &&
            archiveEmailMutation.variables.thread_external_id === message.thread_external_id
        );

      case MessageSessionType.sms:
        return (
          archiveSmsMutation.isPending &&
            archiveSmsMutation.variables.thread_external_id === message.thread_external_id
        );

      case MessageSessionType.vendor:
        return (
          archiveVendorConversationMutation.isPending &&
            archiveVendorConversationMutation.variables.conversation_sid ===
              message.thread_external_id
        );

      default:
        return false;
      }
    },
    [
      archiveEmailMutation.isPending,
      archiveEmailMutation.variables?.thread_external_id,
      archiveSmsMutation.isPending,
      archiveSmsMutation.variables?.thread_external_id,
      archiveVendorConversationMutation.isPending,
      archiveVendorConversationMutation.variables?.conversation_sid,
    ]
  );

  const onViewHistoryAndRespond = useCallback((message: InboundMessageItem) => {
    setThreadToView(message);
    setIsThreadModalOpen(true);
  }, []);

  const columns: GridColDef<InboundMessageItem>[] = useMemo(() => {
    if (isMobile) {
      // Mobile optimized columns
      return [
        {
          field: 'contentWithActions',
          headerName: '',
          flex: 1,
          minWidth: 260,
          renderCell: (params: GridCellParams<InboundMessageItem>) => (
            <Stack spacing={1.5} sx={{ width: '100%', py: 1.5, position: 'relative' }}>
              <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Typography variant="dataGridTitle" sx={{ mr: 1 }}>
                    {params.row.unitName ?? 'Unknown'}
                  </Typography>

                  {params.row.type === 'email' && <Mail02Icon sx={{ fontSize: 16 }} />}
                  {params.row.type === 'vendor' && <Mail02Icon sx={{ fontSize: 16 }} />}
                  {params.row.type === 'sms' && <Phone02Icon sx={{ fontSize: 16 }} />}
                </Stack>

                <RowActionsMenu
                  items={[
                    ...(params.row.status !== MessageStatus.done
                      ? [
                        {
                          label: 'Mark as Done',
                          onClick: async () => onMarkAsDone(params.row),
                          isLoading: getIsMarkAsDoneLoading(params.row),
                        },
                      ]
                      : []),
                    {
                      label: 'View History & Respond',
                      onClick: () => onViewHistoryAndRespond(params.row),
                    },
                  ]}
                />
              </Stack>

              <Typography variant="body3">{params.row.tenantName}</Typography>

              {!!params.row?.isUrgent && (
                <Typography variant="dataGridTitleSmall" color="error">
                  URGENT
                </Typography>
              )}

              {!!params.row.subject && (
                <Typography variant="dataGridTitle">{params.row.subject}</Typography>
              )}

              <Typography variant="dataGridBody">{params.row.body}</Typography>

              {!!params.row.documents?.length && (
                <Stack direction="row" spacing={1} mt={0.5} flexWrap="wrap">
                  {params.row.documents.map(document => (
                    <Button
                      key={document.id}
                      variant="outlined"
                      size="small"
                      color="secondary"
                      startIcon={<FileAttachment01Icon />}
                      sx={{ textTransform: 'none' }}
                      onClick={() => handleOnAttachmentClick(document.id)}
                    >
                      {document.original_name}
                    </Button>
                  ))}
                </Stack>
              )}

              <Stack direction="row" justifyContent="space-between" alignItems="center" mt={0.5}>
                <Typography variant="body4">
                  {getFromNow(new Date(params.row?.updated_at ?? ''))}
                </Typography>

                <Stack direction="row" spacing={1}>
                  {params.row.status !== MessageStatus.done && (
                    <Button
                      variant="outlined"
                      size="small"
                      color="secondary"
                      startIcon={<BoxIcon />}
                      onClick={() => onMarkAsDone(params.row)}
                      disabled={getIsMarkAsDoneLoading(params.row)}
                    >
                      Done
                    </Button>
                  )}
                  <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    onClick={() => onViewHistoryAndRespond(params.row)}
                  >
                    View
                  </Button>
                </Stack>
              </Stack>
            </Stack>
          ),
        },
      ];
    }

    // Desktop columns
    return [
      {
        field: 'tenant',
        headerName: 'Tenant',
        maxWidth: 200,
        flex: 1,
        renderCell: (params: GridCellParams<InboundMessageItem>) => (
          <Stack direction="row" alignItems="center" spacing={1.5}>
            <Avatar
              sx={{
                width: 40,
                height: 40,
              }}
            >
              {getInitialsFromFullName(params.row.tenantName)}
            </Avatar>

            <Stack direction="column" spacing={0}>
              <Typography variant="dataGridTitle">{params.row.unitName ?? 'Unknown'}</Typography>

              <Typography variant="body3">{params.row.tenantName}</Typography>
            </Stack>
          </Stack>
        ),
      },
      {
        field: 'subjectAndBody',
        headerName: 'Subject & Content',
        minWidth: 300,
        flex: 2,
        renderCell: (params: GridCellParams<InboundMessageItem>) => (
          <Stack spacing={0.25}>
            {!!params.row?.isUrgent && (
              <Typography variant="dataGridTitleSmall" color="error">
                URGENT
              </Typography>
            )}

            {!!params.row.subject && (
              <Typography variant="dataGridTitle">{params.row.subject}</Typography>
            )}

            <Typography variant="dataGridBody">{params.row.body}</Typography>

            {!!params.row.documents?.length && (
              <Stack direction="row" spacing={1} mt={1} flexWrap="wrap">
                {params.row.documents.map(document => (
                  <Button
                    key={document.id}
                    variant="outlined"
                    size="small"
                    color="secondary"
                    startIcon={<FileAttachment01Icon />}
                    sx={{ textTransform: 'none' }}
                    onClick={() => handleOnAttachmentClick(document.id)}
                  >
                    {document.original_name}
                  </Button>
                ))}
              </Stack>
            )}
          </Stack>
        ),
      },
      {
        field: 'updated_at',
        headerName: 'Created At',
        width: 140,
        renderCell: (params: GridCellParams<InboundMessageItem>) => (
          <Stack direction="row" alignItems="center" spacing={1}>
            {params.row.type === 'email' && <Mail02Icon />}
            {params.row.type === 'vendor' && <Mail02Icon />}
            {params.row.type === 'sms' && <Phone02Icon />}

            <Typography variant="dataGridBody">
              {getFromNow(new Date(params.row?.updated_at ?? ''))}
            </Typography>
          </Stack>
        ),
      },
      {
        field: 'actions',
        headerName: '',
        width: 50,
        renderCell: (params: GridCellParams<InboundMessageItem>) => (
          <RowActionsMenu
            items={[
              ...(params.row.status !== MessageStatus.done
                ? [
                  {
                    label: 'Mark as Done',
                    onClick: async () => onMarkAsDone(params.row),
                    isLoading: getIsMarkAsDoneLoading(params.row),
                  },
                ]
                : []),
              {
                label: 'View History & Respond',
                onClick: () => onViewHistoryAndRespond(params.row),
              },
            ]}
          />
        ),
      },
    ];
  }, [
    isMobile,
    getIsMarkAsDoneLoading,
    onMarkAsDone,
    onViewHistoryAndRespond,
    handleOnAttachmentClick,
  ]);

  const InboundMessagesNoRowsOverlay = useCallback(
    () => (
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        height={isMobile ? '150px' : '100%'}
        width="100%"
        gap={1}
        sx={{
          py: 3,
          boxShadow: isMobile ? 1 : 'none',
          borderRadius: isMobile ? 1 : 0,
          backgroundColor: isMobile ? 'background.paper' : 'transparent',
          mx: 'auto',
        }}
      >
        <Typography variant="body2" textAlign="center">
          No {title === 'Done' ? 'Archived' : 'Inbound'} Messages found.
        </Typography>
      </Stack>
    ),
    [isMobile, title]
  );

  const isEmpty = useMemo(() => isSuccess && items.length === 0, [isSuccess, items]);

  return (
    <Stack flex={isEmpty ? '0 0 auto' : '1 1 auto'} spacing={3}>
      <Stack direction="row" alignItems="center" spacing={1.5}>
        <AlertCircleIcon color="primary" />

        <Typography variant="dataGridTitle">
          {title} ({items?.length})
        </Typography>
      </Stack>

      {!isEmpty && (
        <DataGrid
          rows={items ?? []}
          columns={columns}
          density="comfortable"
          disableColumnFilter
          disableColumnMenu
          disableEval
          disableRowSelectionOnClick
          getRowHeight={() => 'auto'}
          loading={isLoading}
          sortingMode="client"
          slots={{
            noRowsOverlay: InboundMessagesNoRowsOverlay,
          }}
          sortModel={[{ field: 'updated_at', sort: 'desc' }]}
          hideFooter
          columnHeaderHeight={0}
          columnGroupHeaderHeight={0}
          sx={{
            flex: '0 0 auto',
            height: 'auto',
            '& .MuiDataGrid-topContainer': {
              display: 'none',
            },
            '& .MuiDataGrid-bottomContainer': {
              display: 'none',
            },
            '& .MuiDataGrid-row--lastVisible': {
              borderBottom: 'none',
            },
          }}
        />
      )}

      {isDocumentViewModalOpen && documentIdToView && (
        <DocumentViewModal
          id={documentIdToView}
          onClose={() => {
            setIsDocumentViewModalOpen(false);
            setDocumentIdToView(null);
          }}
        />
      )}

      {isThreadModalOpen && threadToView && (
        <MessageThreadHistoryModal
          threadId={threadToView.thread_external_id}
          type={threadToView.type}
          onClose={() => {
            setIsThreadModalOpen(false);
            setThreadToView(null);
          }}
        />
      )}
    </Stack>
  );
};
