import { Button, IconButton, Stack, Typography } from '@mui/material';
import { DataGrid, GridCellParams, GridSortModel } from '@mui/x-data-grid';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { enqueueSnackbar } from 'notistack';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';

import { environment } from '@env';

import DashboardHeader from '@/@mui/dashboard/DashboardHeader';
import NoRowsOverlay from '@/@mui/data-grid/NoRowsOverlay';
import { ApiRequest_UpdatePhone_User, useUpdatePhone } from '@/api/endpoints/user/useUpdatePhone';
import { PropertyManagementRole } from '@/api/enums';
import { PhoneIcon } from '@/assets/icons/PhoneIcon';
import { PlayIcon } from '@/assets/icons/PlayIcon';
import { StopIcon } from '@/assets/icons/StopIcon';
import { useAuth } from '@/context/AuthProvider';
import { usePropertyManager } from '@/context/PropertyManagerProvider';
import { getFromNow } from '@/utils/date';
import { formatPhoneNumber } from '@/utils/phone';

import FormTextField from '@/components/form/FormTextField';
import ModalWithForm from '@/components/modals/ModalWithForm';

interface TInboundCall {
  id: number;
  tenant_id: number;
  from_number: string;
  to_number: string;
  recording_url: string;
  recording_sid: string;
  conference_sid: string;
  recording_channels: string;
  recording_duration: string;
  recording_start_time: string;
  transcript: string | null;
  tenant?: {
    contact?: {
      name: string;
      phone: string;
    };
  };
}

interface PropertyManagerData {
  id: number;
  limitedUser: {
    id: string;
    email: string;
    phone?: string;
    full_name?: string;
  };
}

interface PropertyManagement {
  id: number;
  manager_id: number;
  location_id: number;
  role: PropertyManagementRole;
  manager: PropertyManagerData;
}

interface CallForwardModalProps {
  onClose: () => void;
}

const CallForwardModal: React.FC<CallForwardModalProps> = ({ onClose }) => {
  const { user } = useAuth();
  const [, setFormError] = useState<Error | null>(null);

  const form = useForm<ApiRequest_UpdatePhone_User>({
    defaultValues: {
      phone: user?.phone ?? '',
    },
  });

  const updatePhoneMutation = useUpdatePhone({
    onError: error => {
      setFormError(error);
    },
    onSuccess: () => {
      onClose();
    },
  });

  const onSubmit = useCallback(
    (data: ApiRequest_UpdatePhone_User) => {
      setFormError(null);
      return updatePhoneMutation.mutateAsync(data);
    },
    [updatePhoneMutation]
  );

  return (
    <ModalWithForm
      title="Update Call Forwarding Number"
      form={form}
      maxWidth="sm"
      onClose={onClose}
      onSubmit={onSubmit}
      buttonLabel="Update Phone Number"
    >
      <Stack spacing={2}>
        <Typography variant="body2">
          When an inbound call comes in for your property's phone number, it will be automatically
          forwarded to this number. Please ensure this number is always up to date.
        </Typography>
        <FormTextField
          label="Your Phone Number"
          placeholder="Enter your phone number"
          size="small"
          error={!!form.formState.errors.phone}
          helperText={form.formState.errors.phone?.message?.toString()}
          control={form.control}
          required
          {...form.register('phone', {
            required: 'Phone Number is required',
          })}
        />
      </Stack>
    </ModalWithForm>
  );
};

const InboundCallListPage: React.FC = () => {
  const { session, user } = useAuth();
  const { selectedPropertyLocation } = usePropertyManager();
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [playingRecordingId, setPlayingRecordingId] = useState<number | null>(null);
  const [sortModel, setSortModel] = useState<GridSortModel>([]);
  const [expanded, setExpanded] = useState<boolean>(false);
  const [isCallForwardModalOpen, setIsCallForwardModalOpen] = useState<boolean>(false);

  const onSortModelChange = useCallback(
    (_sortModel: GridSortModel) => setSortModel(_sortModel),
    []
  );

  const fetchCallsQuery = useQuery({
    queryKey: ['GET', 'inbound-calls', session?.access_token, selectedPropertyLocation?.id],
    queryFn: async () => {
      try {
        const response = await axios.get(
          `${environment.api}/twilio/inbound-calls/${selectedPropertyLocation?.id}`,
          {
            headers: {
              Authorization: `Bearer ${session?.access_token}`,
            },
            params: {
              phone: selectedPropertyLocation?.phone,
            },
          }
        );
        return response.data;
      } catch (error) {
        enqueueSnackbar('Failed to fetch inbound calls.', { variant: 'error' });
        return [];
      }
    },
    enabled:
      !!session?.access_token &&
      !!selectedPropertyLocation?.id &&
      !!selectedPropertyLocation?.phone,
  });

  // Query to check if the user is an admin for the current property location
  const isAdminQuery = useQuery({
    queryKey: ['GET', 'is-admin', selectedPropertyLocation?.id, user?.id],
    queryFn: async () => {
      try {
        const response = await axios.get(`${environment.api}/property-manager/all`, {
          headers: {
            Authorization: `Bearer ${session?.access_token}`,
          },
          params: {
            location_id: selectedPropertyLocation?.id,
          },
        });
        // Check if the current user has the ADMIN role for the current property location
        const propertyManagers = (response.data as PropertyManagement[]) || [];
        const currentUserManagement = propertyManagers.find(
          (pm: PropertyManagement) => pm.manager.limitedUser.id === user?.id
        );
        return currentUserManagement?.role === PropertyManagementRole.ADMIN;
      } catch (error) {
        return false;
      }
    },
    enabled: !!session?.access_token && !!selectedPropertyLocation?.id && !!user?.id,
  });

  const handlePlayRecording = useCallback(
    (recordingUrl: string, callId: number) => {
      if (audioRef.current) {
        if (playingRecordingId === callId) {
          audioRef.current.pause();
          audioRef.current.currentTime = 0;
          setPlayingRecordingId(null);
        } else {
          audioRef.current.src = recordingUrl;
          audioRef.current.play();
          setPlayingRecordingId(callId);
        }
      }
    },
    [playingRecordingId]
  );

  const columns = useMemo(
    () => [
      {
        field: 'caller',
        headerName: 'Caller',
        width: 200,
        renderCell: (params: GridCellParams<TInboundCall>) => (
          <Stack spacing={0.25}>
            <Typography variant="dataGridTitle" noWrap>
              {params.row.tenant?.contact?.name || 'Unknown'}
            </Typography>

            <Typography variant="dataGridBody" noWrap>
              {formatPhoneNumber(params.row.from_number)}
            </Typography>
          </Stack>
        ),
      },
      {
        field: 'recording_duration',
        headerName: 'Duration',
        width: 120,
        valueFormatter: (params: string) => `${params} seconds`,
      },
      {
        field: 'recording_start_time',
        headerName: 'Call Time',
        width: 160,
        valueFormatter: (params: string) => getFromNow(new Date(params)),
      },
      {
        field: 'transcript',
        headerName: 'Transcript',
        width: 300,
        flex: 1,
        renderCell: (params: GridCellParams<TInboundCall>) => (
          <Typography
            variant="dataGridBody"
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: expanded ? undefined : 3,
              WebkitBoxOrient: 'vertical',
              cursor: 'pointer',
            }}
            onClick={() => setExpanded(!expanded)}
          >
            {params.row.transcript || 'No transcript available'}
          </Typography>
        ),
      },
      {
        field: 'actions',
        headerName: 'Recording',
        width: 100,
        renderCell: (params: GridCellParams<TInboundCall>) => (
          <IconButton
            onClick={() => handlePlayRecording(params.row.recording_url, params.row.id)}
            color="primary"
          >
            {playingRecordingId === params.row.id ? <StopIcon /> : <PlayIcon />}
          </IconButton>
        ),
      },
    ],
    [handlePlayRecording, playingRecordingId, expanded]
  );

  const InboundCallListNoRowsOverlay = useCallback(
    () => <NoRowsOverlay entityName="Inbound Call" />,
    []
  );

  return (
    <Stack direction="column" spacing={2} sx={{ flex: '1 1 100%' }}>
      <DashboardHeader hasInnerHtml title="Calls: <em>Inbound</em>" />

      <Stack
        direction={{ xs: 'column', md: 'row' }}
        justifyContent="space-between"
        alignItems={{ xs: 'flex-start', md: 'center' }}
        spacing={2}
      >
        <Typography variant="dataGridBody" sx={{ maxWidth: { md: '70%' } }}>
          View and listen to recordings of inbound calls from tenants. Any inbound calls to your
          property's phone number will be recorded and available here. All inbound calls are
          automatically forwarded to your phone number.
        </Typography>

        {isAdminQuery.data && (
          <Button
            variant="contained"
            color="primary"
            size="small"
            startIcon={<PhoneIcon />}
            onClick={() => setIsCallForwardModalOpen(true)}
            sx={{ alignSelf: { xs: 'flex-start', md: 'center' } }}
          >
            Call Forwarding
          </Button>
        )}
      </Stack>

      <DataGrid
        rows={fetchCallsQuery.data || []}
        columns={columns}
        density="comfortable"
        disableColumnFilter
        disableColumnMenu
        disableEval
        disableRowSelectionOnClick
        getRowHeight={() => 'auto'}
        loading={fetchCallsQuery.isLoading}
        slots={{
          noRowsOverlay: InboundCallListNoRowsOverlay,
        }}
        slotProps={{
          loadingOverlay: {
            variant: 'skeleton',
            noRowsVariant: 'skeleton',
          },
        }}
        sortingMode="server"
        sortModel={sortModel}
        onSortModelChange={onSortModelChange}
      />

      <audio ref={audioRef} style={{ display: 'none' }} controls>
        <track kind="captions" src="" label="English captions" />
      </audio>

      {isCallForwardModalOpen && (
        <CallForwardModal onClose={() => setIsCallForwardModalOpen(false)} />
      )}
    </Stack>
  );
};

export default InboundCallListPage;
