import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { environment } from '@env';

import { ContextSource, TKnowledgeItem } from '@/types/knowledge';
import { ExtractParams } from '@/types/params';

import MuiPageWrapper from '@/@mui/MuiPageWrapper';
import { PMPRoutes } from '@/config/routes';
import { useAuth } from '@/context/AuthProvider';

import BulletPointsList from '@/components/common/BulletPointsList';

const formatDate = (date: Date) => date.toISOString().split('T')[0];
const getDefaultStartDate = () => {
  const date = new Date();
  date.setDate(date.getDate() - 7);
  return formatDate(date);
};

const addDays = (date: string, days: number) => {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + days);
  return formatDate(newDate);
};

const PropertyLocationViewKnowledgeSubpage: React.FC = () => {
  const { session } = useAuth();
  const { id } = useParams<ExtractParams<PMPRoutes.propertyLocationViewKnowledge>>();

  // General knowledge dates
  const [startDate, setStartDate] = useState<string>(getDefaultStartDate());
  const [endDate, setEndDate] = useState<string>(formatDate(new Date()));

  // Topic specific dates
  const [topicStartDate, setTopicStartDate] = useState<string>(getDefaultStartDate());
  const [topicEndDate, setTopicEndDate] = useState<string>(formatDate(new Date()));
  const [topic, setTopic] = useState<string>('');
  const [searchTopic, setSearchTopic] = useState<string>('');

  const fetchKnowledge = useQuery({
    enabled: !!session?.access_token,
    queryKey: ['GET', 'knowledge', id, session?.access_token, startDate, endDate],
    queryFn: async () => {
      const response = await axios.post<TKnowledgeItem>(
        `${environment.api}/knowledge/${id}`,
        {
          context_source: [ContextSource.EMAIL, ContextSource.SMS],
          start_date: startDate,
          end_date: endDate,
        },
        {
          headers: {
            Authorization: `Bearer ${session?.access_token}`,
          },
        }
      );
      return response.data;
    },
  });

  const fetchTopicKnowledge = useQuery({
    enabled: !!session?.access_token && !!searchTopic,
    queryKey: [
      'GET',
      'knowledge',
      'topic',
      id,
      session?.access_token,
      searchTopic,
      topicStartDate,
      topicEndDate,
    ],
    queryFn: async () => {
      const response = await axios.post<TKnowledgeItem>(
        `${environment.api}/knowledge/${id}/topic`,
        {
          context_source: [ContextSource.EMAIL, ContextSource.SMS],
          topic: searchTopic,
          start_date: topicStartDate,
          end_date: topicEndDate,
        },
        {
          headers: {
            Authorization: `Bearer ${session?.access_token}`,
          },
        }
      );
      return response.data;
    },
  });

  const handleSearch = () => {
    setSearchTopic(topic);
  };

  const renderContent = (query: typeof fetchKnowledge) => {
    if (query.isLoading) {
      return (
        <Box
          sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 400 }}
        >
          <CircularProgress />
        </Box>
      );
    }

    return (
      <Box>
        <BulletPointsList text={query?.data?.toString() ?? ''} />
      </Box>
    );
  };

  const isLoading = fetchKnowledge.isLoading || fetchTopicKnowledge.isLoading;

  return (
    <MuiPageWrapper>
      <Stack spacing={3}>
        <Stack spacing={2}>
          <Typography variant="h6">General Knowledge</Typography>
          <Stack direction="row" spacing={2}>
            <TextField
              label="Start Date"
              type="date"
              value={startDate}
              onChange={e => setStartDate(e.target.value)}
              size="small"
              disabled={isLoading}
            />
            <TextField
              label="End Date"
              type="date"
              value={endDate}
              onChange={e => setEndDate(e.target.value)}
              size="small"
              disabled={isLoading}
            />
          </Stack>
          {renderContent(fetchKnowledge)}
        </Stack>

        <Divider sx={{ my: 3 }} />

        <Stack spacing={2}>
          <Typography variant="h6">Topic-Specific Knowledge</Typography>
          <Stack spacing={2}>
            <Stack direction="row" spacing={2}>
              <TextField
                label="Start Date"
                type="date"
                value={topicStartDate}
                onChange={e => setTopicStartDate(e.target.value)}
                size="small"
                disabled={isLoading}
              />
              <TextField
                label="End Date"
                type="date"
                value={topicEndDate}
                onChange={e => setTopicEndDate(e.target.value)}
                inputProps={{
                  min: topicStartDate,
                  max: addDays(topicStartDate, 90),
                }}
                size="small"
                disabled={isLoading}
              />
            </Stack>
            <Stack direction="row" spacing={2}>
              <TextField
                label="Enter Topic"
                value={topic}
                onChange={e => setTopic(e.target.value)}
                placeholder="e.g., maintenance, rent, parking"
                size="small"
                disabled={isLoading}
                fullWidth
              />
              <Button
                variant="contained"
                onClick={handleSearch}
                disabled={isLoading || !topic}
                sx={{ minWidth: 100 }}
              >
                Search
              </Button>
            </Stack>
          </Stack>
          {searchTopic && renderContent(fetchTopicKnowledge)}
        </Stack>
      </Stack>
    </MuiPageWrapper>
  );
};

export default PropertyLocationViewKnowledgeSubpage;
