import {
  Box,
  Stack,
  Typography,
  useTheme,
  useMediaQuery,
  Chip,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { DataGrid, GridCellParams, GridColDef, GridSortModel } from '@mui/x-data-grid';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { enqueueSnackbar } from 'notistack';
import React, { useCallback, useMemo, useState } from 'react';

import { environment } from '@env';

import { MessageSessionType } from '@/types/message';
import { TSMS } from '@/types/sms';

import DashboardHeader from '@/@mui/dashboard/DashboardHeader';
import NoRowsOverlay from '@/@mui/data-grid/NoRowsOverlay';
import { Uuid } from '@/api/utils/sql';
import { BoxIcon } from '@/assets/icons/BoxIcon';
import { Send01Icon } from '@/assets/icons/Send01Icon';
import { useAuth } from '@/context/AuthProvider';
import { usePropertyManager } from '@/context/PropertyManagerProvider';
import { formatFullDateTime } from '@/utils/date';
import { listToLines } from '@/utils/list';
import { formatPhoneNumber } from '@/utils/phone';
import { capitalizeFirstLetter } from '@/utils/text';

import RowActionsMenu from '@/components/common/RowActionsMenu';
import MessageThreadHistoryModal from '@/components/message/MessageThreadHistoryModal';
import MobileCardsContainer from '@/components/MobileCardsContainer';
import SMSMobileCard from '@/components/sms/SMSMobileCard';

const SentSMSListPage: React.FC = () => {
  const { session } = useAuth();
  const { selectedPropertyLocation } = usePropertyManager();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [isHistoryModalOpen, setIsHistoryModalOpen] = useState(false);
  const [smsThreadIdToView, setSmsThreadIdToView] = useState<Uuid | null>(null);

  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: 'created_at',
      sort: 'desc',
    },
  ]);
  const onSortModelChange = useCallback(
    (_sortModel: GridSortModel) => setSortModel(_sortModel),
    []
  );

  const [filter, setFilter] = useState<'sent' | 'archived'>('sent');

  const handleFilterChange = useCallback(
    (_: React.MouseEvent<HTMLElement>, newFilter: 'sent' | 'archived') => {
      if (newFilter !== null) {
        setFilter(newFilter);
      }
    },
    []
  );

  const fetchSMSQuery = useQuery({
    queryKey: ['GET', 'sms', 'sent', session?.access_token, selectedPropertyLocation?.id],
    queryFn: async () => {
      try {
        const response = await axios.get<TSMS[]>(`${environment.api}/twilio/sent`, {
          headers: {
            Authorization: `Bearer ${session?.access_token}`,
          },
          params: {
            locationId: selectedPropertyLocation?.id,
            phone: selectedPropertyLocation?.phone,
          },
        });
        return response.data;
      } catch (error) {
        enqueueSnackbar('Failed to fetch sent SMS messages.', { variant: 'error' });
        return [];
      }
    },
    enabled: !!session?.access_token && !!selectedPropertyLocation?.id,
  });

  const filteredData = useMemo(() => {
    if (!fetchSMSQuery.data) return [];
    return fetchSMSQuery.data.filter(sms => {
      const isArchived = sms.session?.archived ?? false;
      return filter === 'archived' ? isArchived : !isArchived;
    });
  }, [fetchSMSQuery.data, filter]);

  const onViewHistory = useCallback((sms: TSMS) => {
    setSmsThreadIdToView(sms.external_id);
    setIsHistoryModalOpen(true);
  }, []);

  const columns: GridColDef<TSMS>[] = useMemo(
    () => [
      {
        field: 'session',
        headerName: 'Contact',
        width: 250,
        renderCell: (params: GridCellParams<TSMS, TSMS['session']>) =>
          params.value ? (
            <Stack spacing={0.25}>
              <Typography variant="dataGridTitle" noWrap>
                {capitalizeFirstLetter(params.value?.tenant?.full_name ?? 'Unknown')}
              </Typography>

              <Typography variant="dataGridBody" noWrap>
                {formatPhoneNumber(params.value?.from_phone_number)}
              </Typography>

              {params.value?.tenant?.unit && (
                <Typography variant="dataGridSmall">
                  {params.value.tenant.unit.building?.name} - {params.value.tenant.unit.name}
                </Typography>
              )}
            </Stack>
          ) : (
            <Typography variant="dataGridTitle">Unknown</Typography>
          ),
      },
      {
        field: 'first_user_message',
        headerName: 'Message',
        width: 400,
        flex: 1,
        renderCell: (params: GridCellParams<TSMS, TSMS['first_user_message']>) => (
          <Box>
            <Typography variant="dataGridBody" color="text.primary">
              {params.value}
            </Typography>
          </Box>
        ),
      },
      {
        field: 'first_ai_message',
        headerName: 'Response',
        width: 400,
        flex: 1,
        renderCell: (params: GridCellParams<TSMS>) => (
          <Typography variant="dataGridBody">{params.row.first_ai_message}</Typography>
        ),
      },
      {
        field: 'status',
        headerName: 'Status',
        width: 120,
        renderCell: (params: GridCellParams<TSMS>) => (
          <Chip
            label={params.row.session?.archived ? 'Archived' : 'Sent'}
            color={params.row.session?.archived ? 'warning' : 'success'}
            icon={params.row.session?.archived ? <BoxIcon /> : <Send01Icon />}
            variant="light"
            size="small"
          />
        ),
      },
      {
        field: 'created_at',
        headerName: 'Created At',
        width: 130,
        valueFormatter: (value: string) => formatFullDateTime(new Date(value)),
        renderCell: (params: GridCellParams<TSMS, string>) =>
          listToLines(params.formattedValue?.split(',') ?? []),
      },
      {
        field: 'actions',
        headerName: '',
        width: 50,
        align: 'right',
        headerAlign: 'right',
        renderCell: (params: GridCellParams<TSMS>) => (
          <RowActionsMenu
            items={[
              {
                label: 'View History',
                onClick: () => onViewHistory(params.row),
              },
            ]}
          />
        ),
      },
    ],
    [onViewHistory]
  );

  const SentSMSListNoRowsOverlay = useCallback(
    () => <NoRowsOverlay entityName="Sent SMS Message" entityNamePlural="Sent SMS Messages" />,
    []
  );

  return (
    <Stack direction="column" spacing={2} sx={{ flex: '1 1 100%' }}>
      <DashboardHeader
        hasInnerHtml
        title={`SMS: <em>Sent</em>${filteredData ? ` (${filteredData.length})` : ''}`}
      />

      <Stack direction="column" spacing={2}>
        <Typography variant="dataGridBody">
          This list shows all SMS messages that have been sent or archived.
        </Typography>

        <ToggleButtonGroup value={filter} exclusive onChange={handleFilterChange}>
          <ToggleButton value="sent">Sent</ToggleButton>
          <ToggleButton value="archived">Archived</ToggleButton>
        </ToggleButtonGroup>
      </Stack>

      {isMobile ? (
        <MobileCardsContainer>
          {filteredData.map((sms: TSMS) => (
            <SMSMobileCard key={sms.external_id} sms={sms} readOnly onViewHistory={onViewHistory} />
          ))}
          {filteredData.length === 0 && <NoRowsOverlay entityName="Sent SMS Message" />}
        </MobileCardsContainer>
      ) : (
        <DataGrid
          rows={filteredData}
          columns={columns}
          density="comfortable"
          disableColumnFilter
          disableColumnMenu
          disableEval
          disableRowSelectionOnClick
          getRowHeight={() => 'auto'}
          getRowId={row => row.session?.session_id ?? row.external_id}
          loading={fetchSMSQuery.isLoading}
          slots={{
            noRowsOverlay: SentSMSListNoRowsOverlay,
          }}
          slotProps={{
            loadingOverlay: {
              variant: 'skeleton',
              noRowsVariant: 'skeleton',
            },
          }}
          sortingMode="client"
          sortModel={sortModel}
          onSortModelChange={onSortModelChange}
        />
      )}

      {isHistoryModalOpen && !!smsThreadIdToView && (
        <MessageThreadHistoryModal
          threadId={smsThreadIdToView}
          type={MessageSessionType.sms}
          onClose={() => {
            setSmsThreadIdToView(null);
            setIsHistoryModalOpen(false);
          }}
        />
      )}
    </Stack>
  );
};

export default SentSMSListPage;
