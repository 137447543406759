import { Stack } from '@mui/material';

import { useGetAllMessages } from '@/api/endpoints/message/useGetAllMessages';
import { usePropertyManager } from '@/context/PropertyManagerProvider';

import LoadingState from '@/components/LoadingState';

import { Section } from './Section';

const InboundMessagesWithTenantsSubpage: React.FC = () => {
  const { selectedPropertyLocation } = usePropertyManager();

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const getAllMessages = useGetAllMessages(selectedPropertyLocation!);

  return (
    <Stack direction="column" spacing={3} sx={{ flex: '1 1 100%', position: 'relative' }}>
      {getAllMessages.isLoading ? (
        <LoadingState />
      ) : (
        <>
          <Section
            title="Needs Response"
            items={getAllMessages.needsFirstResponse ?? []}
            isLoading={getAllMessages.isLoading}
            isSuccess={getAllMessages.isSuccess}
            onItemArchived={() => getAllMessages.refetch()}
          />

          {/* <Section
            title="Investigating"
            items={getAllMessages.investigating ?? []}
            isLoading={getAllMessages.isLoading}
            isSuccess={getAllMessages.isSuccess}
            onItemArchived={() => getAllMessages.refetch()}
          />

          <Section
            title="Waiting for Customer"
            items={getAllMessages.waitingForCustomer ?? []}
            isLoading={getAllMessages.isLoading}
            isSuccess={getAllMessages.isSuccess}
            onItemArchived={() => getAllMessages.refetch()}
          /> */}

          <Section
            title="Done"
            items={getAllMessages.done ?? []}
            isLoading={getAllMessages.isLoading}
            isSuccess={getAllMessages.isSuccess}
            onItemArchived={() => getAllMessages.refetch()}
          />
        </>
      )}
    </Stack>
  );
};

export default InboundMessagesWithTenantsSubpage;
