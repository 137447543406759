import React from 'react';
import { useParams } from 'react-router-dom';

import { ExtractParams } from '@/types/params';

import { PMPRoutes } from '@/config/routes';
import { useAuth } from '@/context/AuthProvider';
import { useDocumentList } from '@/hooks/useDocumentList';
import { DocumentListLayout } from '@/pages/property-manager-portal/DocumentListLayout';

const BuildingViewDocumentListSubpage: React.FC = () => {
  const { session } = useAuth();

  const { id } = useParams<ExtractParams<PMPRoutes.buildingViewDocumentList>>();

  const { layoutProps } = useDocumentList({
    entityType: 'building',
    entityId: id,
    accessToken: session?.access_token,
  });

  return <DocumentListLayout {...layoutProps} />;
};

export default BuildingViewDocumentListSubpage;
