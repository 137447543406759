import { Button, Stack, Typography, CircularProgress } from '@mui/material';
import { Paddle, PaddleEventData, initializePaddle } from '@paddle/paddle-js';
import { useEffect, useState } from 'react';

import { environment } from '@env';

import { usePaddleCheckout } from '@/api/endpoints/accountSubscription';

interface PaddleCheckoutProps {
  priceId: string;
  userId: string;
  buttonText?: string;
  disabled?: boolean;
}

const PaddleCheckout: React.FC<PaddleCheckoutProps> = ({
  priceId,
  userId,
  buttonText = 'Subscribe Now',
  disabled = false,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [paddle, setPaddle] = useState<Paddle | null>(null);
  const { handleCheckoutCompleted } = usePaddleCheckout();

  useEffect(() => {
    initializePaddle({
      token: environment.paddleClientToken,
      environment: environment.stage === 'production' ? 'production' : 'sandbox', // Use environment config when ready
      eventCallback: (data: PaddleEventData) => {
        if (data.name === 'checkout.completed') {
          handleCheckoutCompleted();
        }
      }
    })
      .then((paddleInstance) => {
        if (paddleInstance) {
          setPaddle(paddleInstance);
        } else {
          setError('Failed to initialize payment system: No Paddle instance returned');
        }
        setIsLoading(false);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error('Error initializing Paddle:', err);
        setError('Failed to initialize payment system');
        setIsLoading(false);
      });

    // No cleanup needed since we're not adding script tag anymore
  }, [handleCheckoutCompleted]);

  const handleCheckout = () => {
    if (isLoading || !paddle || disabled) return;

    // Don't proceed if priceId is empty
    if (!priceId) {
      setError('No valid price ID provided. Cannot proceed with checkout.');
      return;
    }

    try {
      // Open the Paddle Checkout overlay
      paddle.Checkout.open({
        items: [
          {
            priceId,
            quantity: 1,
          },
        ],
        customData: {
          user_id: userId,
        },
        settings: {
          displayMode: 'overlay',
          theme: 'light',
        },
      });
    } catch (err) {
      setError('Failed to open checkout');
    }
  };

  if (error) {
    return (
      <Typography color="error">{error}. Please try again later or contact support.</Typography>
    );
  }

  return (
    <Stack spacing={2} alignItems="center">
      <Button
        variant="contained"
        color="primary"
        onClick={handleCheckout}
        disabled={isLoading || disabled || !priceId}
        fullWidth
        size="large"
      >
        {isLoading ? <CircularProgress size={24} /> : buttonText}
      </Button>
    </Stack>
  );
};

export default PaddleCheckout;
