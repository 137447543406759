import { useEffect, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { ExtractParams } from '@/types/params';

import { useGenerateEmailAddress } from '@/api/endpoints/propertyLocation/useGenerateEmailAddress';
import { PMPRoutes } from '@/config/routes';
import { useOnboardingProgress } from '@/context/OnboardingProgressProvider';
import { getNextOnboardingStep } from '@/utils/onboarding';

import LoadingState from '@/components/LoadingState';

const PropertyLocationEmailCreatePage: React.FC = () => {
  const { id } = useParams<ExtractParams<PMPRoutes.propertyLocationEmailCreate>>();
  const history = useHistory();
  const { onboardingProgress } = useOnboardingProgress();
  const hasMutationBeenCalled = useRef(false);

  const generateEmailAddressMutation = useGenerateEmailAddress(Number(id), {
    onSuccess(data) {
      history.replace(
        ...getNextOnboardingStep(PMPRoutes.propertyLocationEmailCreate, {
          ...onboardingProgress,
          hasEmailAddress: !!data.email,
        })
      );
    },
    onError(error) {
      history.replace(
        ...getNextOnboardingStep(PMPRoutes.propertyLocationEmailCreate, {
          ...onboardingProgress,
        })
      );
    },
  });

  useEffect(() => {
    if (hasMutationBeenCalled.current) return;

    hasMutationBeenCalled.current = true;
    generateEmailAddressMutation.mutate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <LoadingState />;
};

export default PropertyLocationEmailCreatePage;
