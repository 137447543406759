import { Stack, ToggleButtonGroup, ToggleButton, Typography } from '@mui/material';
import React from 'react';
import { useLocation, Switch, Route, Redirect, Link as RouterLink } from 'react-router-dom';

import DashboardHeader from '@/@mui/dashboard/DashboardHeader';
import { PMPRoutes } from '@/config/routes';

import InboundMessagesWithTenantsSubpage from './inbound-messages/InboundMessagesWithTenantsSubpage';
import InboundMessagesWithVendorsSubpage from './inbound-messages/InboundMessagesWithVendorsSubpage';

const InboundMessagesViewPage: React.FC = () => {
  const location = useLocation();

  return (
    <Stack direction="column" spacing={2} sx={{ flex: '1 1 100%' }}>
      <DashboardHeader hasInnerHtml title="Messages: <em>Inbound</em>" />

      <Typography variant="dataGridBody">
        View and manage inbound messages from tenants and vendors.
      </Typography>

      <ToggleButtonGroup value={location.pathname} role="navigation" exclusive>
        <ToggleButton
          value={PMPRoutes.inboundMessagesWithTenants}
          component={RouterLink}
          to={PMPRoutes.inboundMessagesWithTenants}
        >
          Tenants
        </ToggleButton>

        <ToggleButton
          value={PMPRoutes.inboundMessagesWithVendors}
          component={RouterLink}
          to={PMPRoutes.inboundMessagesWithVendors}
        >
          Vendors
        </ToggleButton>
      </ToggleButtonGroup>

      <Switch>
        <Route
          exact
          path={PMPRoutes.inboundMessagesWithTenants}
          component={InboundMessagesWithTenantsSubpage}
        />
        <Route
          exact
          path={PMPRoutes.inboundMessagesWithVendors}
          component={InboundMessagesWithVendorsSubpage}
        />
        <Route>
          <Redirect to={PMPRoutes.inboundMessagesWithTenants} />
        </Route>
      </Switch>
    </Stack>
  );
};

export default InboundMessagesViewPage;
