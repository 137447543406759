import {
  Box,
  Stack,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Button,
} from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { enqueueSnackbar } from 'notistack';
import { useCallback, useMemo, useState } from 'react';

import DashboardHeader from '@/@mui/dashboard/DashboardHeader';
import NoRowsOverlay from '@/@mui/data-grid/NoRowsOverlay';
import MuiPageWrapper from '@/@mui/MuiPageWrapper';
import {
  ApiResponse_GetAll_Invoice,
  useGetAllInvoices,
} from '@/api/endpoints/invoice/useGetAllInvoices';
import { InvoiceModel } from '@/api/models/ai/invoice';
import { DotsVerticalIcon } from '@/assets/icons/DotsVerticalIcon';
import { TableIcon } from '@/assets/icons/TableIcon';
import { usePropertyManager } from '@/context/PropertyManagerProvider';
import { formatFullDateTime } from '@/utils/date';
import { extractDocumentIdFromUrl } from '@/utils/invoice';

import DocumentViewModal from '@/components/document/DocumentViewModal';
import InvoiceApproveModal from '@/components/invoice/InvoiceApproveModal';
import InvoiceSpreadsheetDeleteModal from '@/components/invoice/InvoiceSpreadsheetDeleteModal';

const getStatusColor = (status: string): string => {
  switch (status) {
  case 'valid':
    return 'success.main';
  case 'pending':
    return 'warning.main';
  default:
    return 'error.main';
  }
};

const InvoiceListPage: React.FC = () => {
  const { selectedPropertyLocation } = usePropertyManager();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedInvoice, setSelectedInvoice] = useState<InvoiceModel | null>(null);

  const [isInvoiceApproveModalOpen, setIsInvoiceApproveModalOpen] = useState(false);
  const [isDeleteInvoiceModalOpen, setIsDeleteInvouceModalOpen] = useState(false);
  const [isDocumentViewModalOpen, setIsDocumentViewModalOpen] = useState(false);

  const onMenuOpen = useCallback((target: HTMLElement, invoice: InvoiceModel) => {
    setAnchorEl(target);
    setSelectedInvoice(invoice);
  }, []);

  const onMenuClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const onApproveClicked = useCallback(() => {
    setIsInvoiceApproveModalOpen(true);
    onMenuClose();
  }, [onMenuClose]);

  const onInvoiceApproveModalClose = useCallback(() => {
    setIsInvoiceApproveModalOpen(false);
    setSelectedInvoice(null);
    setAnchorEl(null);
  }, []);

  const onPreviewClick = useCallback(() => {
    setIsDocumentViewModalOpen(true);
    onMenuClose();
  }, [onMenuClose]);

  const onDocumentViewModalClose = useCallback(() => {
    setIsDocumentViewModalOpen(false);
    setSelectedInvoice(null);
  }, []);

  const getAllInvoicesQuery = useGetAllInvoices({ location_id: selectedPropertyLocation?.id });

  const onViewTrackingSheet = useCallback(() => {
    const spreadsheetId = selectedPropertyLocation?.metadata?.invoice_spreadsheet_id;
    if (!spreadsheetId) {
      enqueueSnackbar(
        'Spreadsheet not found. Please approve an invoice first to generate the spreadsheet.',
        {
          variant: 'warning',
        }
      );
      return;
    }
    window.open(`https://docs.google.com/spreadsheets/d/${spreadsheetId}`, '_blank');
  }, [selectedPropertyLocation]);

  const onDeleteSpreadsheetClick = useCallback(() => {
    setIsDeleteInvouceModalOpen(true);
  }, []);

  const onInvoiceSpreadsheetDeleteModalClose = useCallback(() => {
    setIsDeleteInvouceModalOpen(false);
  }, []);

  const InvoiceListNoRowsOverlay = useCallback(() => <NoRowsOverlay entityName="Invoice" />, []);

  const columns: GridColDef<ApiResponse_GetAll_Invoice[0]>[] = useMemo(
    () => [
      {
        field: 'invoice_number',
        headerName: 'Invoice #',
        width: 120,
        renderCell: (params: GridRenderCellParams<ApiResponse_GetAll_Invoice[0]>) => (
          <Typography variant="body2">{params.row.custom_metadata.invoice_number}</Typography>
        ),
      },
      {
        field: 'vendor_name',
        headerName: 'Vendor',
        width: 150,
        renderCell: (params: GridRenderCellParams<ApiResponse_GetAll_Invoice[0]>) => (
          <Typography variant="body2">{params.row.custom_metadata.vendor_name}</Typography>
        ),
      },
      {
        field: 'description',
        headerName: 'Description',
        flex: 1,
        renderCell: (params: GridRenderCellParams<ApiResponse_GetAll_Invoice[0]>) => (
          <Typography
            variant="body2"
            sx={{
              maxWidth: '100%',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {params.row.custom_metadata.description}
          </Typography>
        ),
      },
      {
        field: 'due_date',
        headerName: 'Due Date',
        width: 180,
        renderCell: (params: GridRenderCellParams<ApiResponse_GetAll_Invoice[0]>) => (
          <Typography variant="body2">
            {formatFullDateTime(new Date(params.row.custom_metadata.due_date))}
          </Typography>
        ),
      },
      {
        field: 'amount',
        headerName: 'Amount',
        width: 120,
        renderCell: (params: GridRenderCellParams<ApiResponse_GetAll_Invoice[0]>) => (
          <Typography variant="body2">${params.row.custom_metadata.amount.toFixed(2)}</Typography>
        ),
      },
      {
        field: 'status',
        headerName: 'Status',
        width: 120,
        renderCell: (params: GridRenderCellParams<ApiResponse_GetAll_Invoice[0]>) => (
          <Typography
            variant="body2"
            sx={{
              textTransform: 'capitalize',
              color: getStatusColor(params.row.status),
            }}
          >
            {params.row.status}
          </Typography>
        ),
      },
      {
        field: 'actions',
        headerName: 'Actions',
        width: 100,
        align: 'right',
        headerAlign: 'right',
        sortable: false,
        renderCell: (params: GridRenderCellParams<ApiResponse_GetAll_Invoice[0]>) => (
          <IconButton onClick={event => onMenuOpen(event.currentTarget, params.row)} size="small">
            <DotsVerticalIcon />
          </IconButton>
        ),
      },
    ],
    [onMenuOpen]
  );

  return (
    <MuiPageWrapper>
      <Stack spacing={3}>
        <DashboardHeader hasInnerHtml title="Invoices: <em>All</em>">
          <Stack direction="row" spacing={2}>
            {selectedPropertyLocation?.metadata?.invoice_spreadsheet_id && (
              <>
                <Button variant="outlined" color="error" onClick={onDeleteSpreadsheetClick}>
                  Delete Tracking Sheet
                </Button>
                <Button variant="contained" startIcon={<TableIcon />} onClick={onViewTrackingSheet}>
                  View Tracking Sheet
                </Button>
              </>
            )}
          </Stack>
        </DashboardHeader>

        <Typography variant="dataGridBody">
          View and manage invoice details for your property.
        </Typography>

        <Box sx={{ width: '100%', height: 600 }}>
          <DataGrid
            rows={getAllInvoicesQuery.data ?? []}
            columns={columns}
            density="comfortable"
            disableColumnFilter
            disableColumnMenu
            disableEval
            loading={getAllInvoicesQuery.isLoading}
            slots={{
              noRowsOverlay: InvoiceListNoRowsOverlay,
            }}
            slotProps={{
              loadingOverlay: {
                variant: 'skeleton',
                noRowsVariant: 'skeleton',
              },
            }}
            sortingMode="client"
            disableRowSelectionOnClick
            getRowHeight={() => 'auto'}
          />
        </Box>
      </Stack>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={onMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={onPreviewClick}>Preview</MenuItem>
        <MenuItem onClick={onApproveClicked}>Approve</MenuItem>
      </Menu>

      {isInvoiceApproveModalOpen && selectedInvoice && (
        <InvoiceApproveModal invoice={selectedInvoice} onClose={onInvoiceApproveModalClose} />
      )}

      {isDeleteInvoiceModalOpen && !!selectedPropertyLocation && (
        <InvoiceSpreadsheetDeleteModal
          onClose={onInvoiceSpreadsheetDeleteModalClose}
          propertyLocationId={selectedPropertyLocation.id}
        />
      )}

      {isDocumentViewModalOpen && selectedInvoice && (
        <DocumentViewModal
          id={extractDocumentIdFromUrl(selectedInvoice.url)}
          onClose={onDocumentViewModalClose}
        />
      )}
    </MuiPageWrapper>
  );
};

export default InvoiceListPage;
