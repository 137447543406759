import { Link, Stack, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams, GridSortModel } from '@mui/x-data-grid';
import { Fragment, useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { ExtractParams } from '@/types/params';

import NoRowsOverlay from '@/@mui/data-grid/NoRowsOverlay';
import {
  ApiResponse_GetAll_Tenant,
  useGetAllTenants,
} from '@/api/endpoints/tenant/useGetAllTenants';
import { PMPRoutes } from '@/config/routes';
import { getFromNow } from '@/utils/date';
import { formatPhoneNumber } from '@/utils/phone';
import { getResolvedRoutePath } from '@/utils/router';

import RowActionsMenu from '@/components/common/RowActionsMenu';
import TenantDeleteModal from '@/components/tenant/TenantDeleteModal';
import TenantRetrieveModal from '@/components/tenant/TenantRetrieveModal';

const UnitViewPastTenantListSubpage: React.FC = () => {
  const { id } = useParams<ExtractParams<PMPRoutes.unitView>>();

  const [isTenantDeleteModalOpen, setIsTenantDeleteModalOpen] = useState(false);
  const [isTenantRetrieveModalOpen, setIsTenantRetrieveModalOpen] = useState(false);

  const [tenantToDelete, setTenantToDelete] = useState<ApiResponse_GetAll_Tenant[0] | null>(null);
  const [tenantToRetrieve, setTenantToRetrieve] = useState<ApiResponse_GetAll_Tenant[0] | null>(
    null
  );

  const [sortModel, setSortModel] = useState<GridSortModel>([]);
  const onSortModelChange = useCallback(
    (_sortModel: GridSortModel) => setSortModel(_sortModel),
    []
  );

  const getAllTenantsQuery = useGetAllTenants({ unit_id: Number(id), is_archived: true });

  const onRetrieveTenantClick = useCallback((tenant: ApiResponse_GetAll_Tenant[0]) => {
    setTenantToRetrieve(tenant);
    setIsTenantRetrieveModalOpen(true);
  }, []);

  const onDeleteTenantClick = useCallback((tenant: ApiResponse_GetAll_Tenant[0]) => {
    setTenantToDelete(tenant);
    setIsTenantDeleteModalOpen(true);
  }, []);

  const columns: GridColDef<ApiResponse_GetAll_Tenant[0]>[] = useMemo(
    () => [
      {
        field: 'full_name',
        headerName: 'Name',
        flex: 1,
        renderCell: (params: GridRenderCellParams<ApiResponse_GetAll_Tenant[0]>) => (
          <Link href={getResolvedRoutePath(PMPRoutes.tenantView, { id: String(params.id) })}>
            {params.value}
          </Link>
        ),
      },
      {
        field: 'contacts',
        headerName: 'Contact Info',
        flex: 1,
        renderCell: (params: GridRenderCellParams<ApiResponse_GetAll_Tenant[0]>) => (
          <Stack direction="column" spacing={0.25} sx={{ display: 'flex', flex: 1 }}>
            {params.row.contacts.map(contact => (
              <Fragment key={contact.id}>
                {!!contact.email && <Typography variant="body4">{contact.email}</Typography>}

                {!!contact.phone && (
                  <Typography variant="body4">{formatPhoneNumber(contact.phone)}</Typography>
                )}
              </Fragment>
            ))}
          </Stack>
        ),
      },
      {
        field: 'archived_at',
        headerName: 'Archived At',
        width: 180,
        valueFormatter: (value: ApiResponse_GetAll_Tenant[0]['archived_at']) =>
          value ? getFromNow(new Date(value)) : '—',
      },
      {
        field: 'actions',
        headerName: '',
        sortable: false,
        align: 'right',
        headerAlign: 'right',
        width: 50,
        renderCell: (params: GridRenderCellParams<ApiResponse_GetAll_Tenant[0]>) => (
          <RowActionsMenu
            items={[
              {
                label: 'Retrieve',
                onClick: () => onRetrieveTenantClick(params.row),
              },
              {
                label: 'Delete',
                onClick: () => onDeleteTenantClick(params.row),
              },
            ]}
          />
        ),
      },
    ],
    [onDeleteTenantClick, onRetrieveTenantClick]
  );

  const UnitViewTenantListNoRowsOverlay = useCallback(
    () => <NoRowsOverlay entityName="Past Tenant" />,
    []
  );

  return (
    <Stack direction="column" spacing={2} sx={{ flex: '1 1 100%', position: 'relative' }}>
      <DataGrid
        rows={getAllTenantsQuery.data ?? []}
        columns={columns}
        density="comfortable"
        disableColumnFilter
        disableColumnMenu
        disableEval
        disableRowSelectionOnClick
        loading={getAllTenantsQuery.isLoading}
        slots={{
          noRowsOverlay: UnitViewTenantListNoRowsOverlay,
        }}
        slotProps={{
          loadingOverlay: {
            variant: 'skeleton',
            noRowsVariant: 'skeleton',
          },
        }}
        sortingMode="server"
        sortModel={sortModel}
        onSortModelChange={onSortModelChange}
        getRowHeight={() => 'auto'}
      />

      {isTenantRetrieveModalOpen && !!tenantToRetrieve && (
        <TenantRetrieveModal
          onClose={() => {
            setIsTenantRetrieveModalOpen(false);
            setTenantToRetrieve(null);
          }}
          tenant={tenantToRetrieve}
        />
      )}

      {isTenantDeleteModalOpen && !!tenantToDelete && (
        <TenantDeleteModal
          onClose={() => {
            setIsTenantDeleteModalOpen(false);
            setTenantToDelete(null);
          }}
          tenant={tenantToDelete}
        />
      )}
    </Stack>
  );
};

export default UnitViewPastTenantListSubpage;
