import { Box, Card, IconButton, Stack, TextField, Typography } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { useParams } from 'react-router-dom';

import { environment } from '@env';

import { ConversationDetails, Message } from '@/types/conversation';
import { MessageSessionType, MessageThreadRole, TMessageThread } from '@/types/message';

import MuiPageWrapper from '@/@mui/MuiPageWrapper';
import { Send01Icon } from '@/assets/icons/Send01Icon';
import { useAuth } from '@/context/AuthProvider';

import MessageThreadMessageList from '@/components/message/MessageThreadMessageList';

interface RouteParams {
  id: string;
}

const ConversationViewPage = () => {
  const { id } = useParams<RouteParams>();
  const queryClient = useQueryClient();
  const { session } = useAuth();

  const { data: conversation, isLoading } = useQuery({
    queryKey: ['conversations', id],
    queryFn: async () => {
      const response = await axios.get<ConversationDetails>(
        `${environment.api}/conversation/${id}/messages`,
        {
          headers: {
            Authorization: `Bearer ${session?.access_token}`,
          },
        }
      );
      return response.data;
    },
  });

  const sendMessageMutation = useMutation({
    mutationFn: async (content: string) => {
      const response = await axios.post(
        `${environment.api}/conversation/${id}/send`,
        {
          content,
        },
        {
          headers: {
            Authorization: `Bearer ${session?.access_token}`,
          },
        }
      );
      return response.data;
    },
    onSuccess: newMessage => {
      // Update the conversation in the cache
      queryClient.setQueryData<ConversationDetails>(['conversations', id], oldData => {
        if (!oldData) return oldData;

        const newMessageWithDefaults = {
          ...newMessage,
          sender_name: newMessage.sender_name || newMessage.sender,
          participant_sid: newMessage.participant_sid || undefined,
        };

        return {
          ...oldData,
          messages: [...oldData.messages, newMessageWithDefaults],
        };
      });
    },
  });

  const handleSendMessage = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const message = formData.get('message') as string;
    if (message.trim()) {
      sendMessageMutation.mutate(message);
      (event.target as HTMLFormElement).reset();
    }
  };

  // Convert conversation to message thread format
  const messageThread: TMessageThread | null = conversation
    ? {
      session: {
        session_id: conversation.id,
        type: MessageSessionType.sms,
        tenant_id: -1,
        building_id: -1,
        property_location_id: -1,
        from: {
          name: conversation.title,
          source: 'sms',
          to: conversation.messages[0]?.sender || '',
        },
        subject: conversation.title,
        updated_at: conversation.updated_at,
        created_at: conversation.created_at,
      },
      messages: conversation.messages.map((msg: Message) => ({
        id: msg.id,
        external_id: msg.external_id,
        message: msg.body || '',
        role:
            msg.sender === conversation.messages[0]?.sender
              ? MessageThreadRole.tenant
              : MessageThreadRole.property_manager,
        created_at: new Date(msg.created_at),
        updated_at: new Date(msg.created_at),
        sender_name: msg.sender_name,
        participant_sid: msg.participant_sid || undefined,
        documents: msg.documents || [],
      })),
    }
    : null;

  if (isLoading) {
    return (
      <MuiPageWrapper>
        <Typography>Loading...</Typography>
      </MuiPageWrapper>
    );
  }

  if (!conversation) {
    return (
      <MuiPageWrapper>
        <Typography>Conversation not found</Typography>
      </MuiPageWrapper>
    );
  }

  return (
    <MuiPageWrapper>
      <Stack spacing={3}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h4">{conversation.title}</Typography>
          <Typography variant="body2" color="text.secondary">
            {conversation.participant_count} participants
          </Typography>
        </Box>

        <Card sx={{ display: 'flex', flexDirection: 'column', height: '70vh' }}>
          <MessageThreadMessageList messageThread={messageThread} isLoading={isLoading} />

          <Box
            component="form"
            onSubmit={handleSendMessage}
            sx={{
              p: 2,
              borderTop: 1,
              borderColor: 'divider',
              bgcolor: 'background.paper',
            }}
          >
            <Stack direction="row" spacing={2}>
              <TextField
                fullWidth
                name="message"
                placeholder="Type a message..."
                variant="outlined"
                size="small"
                disabled={sendMessageMutation.isPending}
              />
              <IconButton type="submit" color="primary" disabled={sendMessageMutation.isPending}>
                <Send01Icon />
              </IconButton>
            </Stack>
          </Box>
        </Card>
      </Stack>
    </MuiPageWrapper>
  );
};

export default ConversationViewPage;
